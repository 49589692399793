/** Homepage **/


.home-page {
	&__bg {
		position: absolute;
		z-index: 1;
	}

	&__main {
		position: relative; 
	}

    &__intro {
    }

    &__authors {
        margin-bottom: 95px;

        @include mq_upto($bp_medium) {
            margin-top: 70px;
        }

        @include mq_for($bp_medium) {
            margin-top: 100px;
        }
    }

    &__banners {
        @include mq_upto($bp_medium) {
            margin-bottom: 80px;
        }

        @include mq_for($bp_medium) {
            margin-bottom: 92px;
        }

    }

    &__journal-articles {
    }

    &__email-banner {
        @include mq_upto($bp_large) {
            margin-top: 90px;
            margin-bottom: 80px;
        }

        @include mq_for($bp_large) {
            margin-top: 60px;
            margin-bottom: 100px;
        }
    }

    &__email-banner-inner {
        position: relative;
        z-index: 5; // higher than decor elements
    }

    &__email-banner-decor {
        position: absolute;
        z-index: 1; // lower than content of the block

        background-repeat: no-repeat;

        &--left {
            width: 214px;
            height: 172px;

            @include mq_upto($bp_medium) {
                display: none;
            }

            @include mq_between($bp_medium, $bp_large) {
                top: -43px;
                left: -80px;
            }

            @include mq_for($bp_large) {
                top: -68px;
                left: 0;
            }
        }

        &--right {
            width: 202px;
            height: 201px;

            @include mq_upto($bp_medium) {
                display: none;
            }

            @include mq_between($bp_medium, $bp_large) {
                bottom: -45px;
                right: -116px;
                transform: rotate(123deg);
            }

            @include mq_for($bp_large) {
                bottom: -107px;
                right: 24px;
                transform: rotate(59deg);
            }
        }

        &--left-mob {
            width: 176px;
            height: 151px;

            @include mq_upto($bp_medium) {
                top: -40px;
                left: -65px;
            }

            @include mq_for($bp_medium) {
                display: none;
            }
        }

        &--right-mob {
            width: 109px;
            height: 165px;

            @include mq_upto($bp_medium) {
                bottom: -68px;
                right: -32px;
                transform: rotate(61deg);
            }

            @include mq_for($bp_medium) {
                display: none;
            }
        }
    }

    &__faq {
        margin-top: 80px;
    }
}


.home-first-screen {
	
	@include upto(large-screens) {
		padding: $base*6 0 $base*8;

		&__main {
			margin-bottom: $base*6;
		}
	}

	@include for(large-screens) {
		padding: $base*20 0;

		display: grid;
		gap: $base*14;
		grid-template-columns: 1fr 396px;

		&__main {
			
		}

		&__aside {
			max-width: 396px;
		}
	}
}

.home-intro {
    position: relative;

    &__bg {
        position: absolute;
        z-index: 1;
    }

    &__header {
        position: relative;
        z-index: 10;
    }

    &__text {
        position: relative;
        z-index: 10;

        @include home-text-large();

        h2 {
            @include h2-title();
        }
    }

    .intro-row {
        font-weight: 600;
        background-color: $green;
        background-image: linear-gradient(to left, $blue, $green);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }
    
    &__subscribe {
        margin-top: 35px;
    }

    &__subscribe-link {
        @include gradient-button-base();
        position: relative;
        z-index: 2; // SVG background has z-index: 1
    }

    svg {
        width: 100%;
        height: auto;
    }

    @include upto(large-screens) {
        &__header {
            margin-bottom: $base*4;
        }

        &__bg {
            width: 100%;
            top: -36px;
            left: -30px;
        }

        .intro-row {
            font-size: 36px;
            line-height: 42px;
        }

        &__text h2 {
            margin-top: $base*4;
        }
    }

    @include for(large-screens) {

        &__bg {
            top: -85px;
            left: -65px;
            width: 630px;
            height: 525px;
        }

        &__header {
            margin-bottom: $base*6;
        }

        .intro-row {
            font-size: 43px;
            line-height: 58px;
        }

        &__text h2 {
            margin-top: $base*10;
        }
    }
}


.home-intro-2 {
    position: relative;
    letter-spacing: 0; // because of the changed global value

    @include mq_upto($bp_large) {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    @include mq_for($bp_large) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
    }

    &__inner {
        position: relative;
        z-index: 2; // higher than decor

        @include mq_for($bp_large) {
            padding-bottom: 20px;
        }
    }

    &__title {
        margin-left: auto;
        margin-right: auto;
        position: relative;

        @include link-gradient();

        @include mq_upto($bp_large) {
            max-width: 600px;
            margin-bottom: 24px;
            padding-left: 20px;
            padding-right: 10px;

            font: 600 36px/40px $ff-main;
            text-align: left;
        }

        @include mq_for($bp_large) {
            max-width: 1000px;
            margin-bottom: 36px;

            font: 600 64px/70.4px $ff-main;
            text-align: center;
        }
    }

    &__desc {
        margin-left: auto;
        margin-right: auto;

        @include mq_upto($bp_large) {
            max-width: 600px;
            margin-bottom: 23px;
            padding-left: 20px;
            padding-right: 10px;

            font: 15px/21px $ff-main;
            text-align: left;
        }

        @include mq_for($bp_large) {
            max-width: 710px;
            margin-bottom: 40px;

            font: 500 18px/27px $ff-main;
            text-align: center;
        }
    }

    &__links {
        display: flex;
        align-items: center;
        max-width: 459px;
        margin-left: auto;
        margin-right: auto;

        border-radius: 500px;

        @include mq_for($bp_388) {
            @include light_dark($prop--background-color, #F4F1EF, darken($dark, 5%));
        }

        @include mq_upto($bp_small) {
            margin-left: 20px;
            margin-right: 20px;
        }

        @include mq_upto($bp_large) {
            max-width: 365px;
            height: 56px;
        }

        @include mq_for($bp_large) {
            height: 60px;
        }
    }

    &__link-1 {}

    &__cards-block {
        display: flex;
        align-items: center;
        margin-left: auto;

        @include mq_upto($bp_388) {
            flex-direction: column;
            align-items: flex-start;
            padding-right: 16px;
        }

        @include mq_between($bp_388, $bp_large) {
            padding-right: 20px;
        }

        @include mq_for($bp_large) {
            padding-right: 28px;
        }
    }

    &__cards-block-items {
        line-height: 1 !important;
    }

    &__cards-block-item {
        display: inline-block;
        outline: 2px solid $color-white;
        overflow: hidden;
        border-radius: 100%;

        @include mq_upto($bp_large) {
            width: 27px;
            height: 27px;

            &:not(:first-child) {
                margin-left: -11px;
            }
        }

        @include mq_for($bp_large) {
            width: 30px;
            height: 30px;

            &:not(:first-child) {
                margin-left: -13px;
            }
        }

        img {
            display: block;
            width: 30px;
            height: 30px;
        }
    }

    &__link-text {
        @include theme-prop($prop--color, $key--text-body);

        @include mq_upto($bp_388) {
            margin-left: 0;
        }

        @include mq_between($bp_388, $bp_large) {
            margin-left: 6px;
        }

        @include mq_upto($bp_large) {
            font: 500 15px/21px $ff-main;
        }

        @include mq_for($bp_large) {
            margin-left: 10px;
            font: 500 16px/22.4px $ff-main;
        }
    }

    &__decor {
        position: absolute;
        z-index: 1; // lower than &__inner

        background-repeat: no-repeat;
        opacity: 0.3;

        @include mq_for($bp_large) {
            width: 582px;
            height: 458px;
        }

        @include mq_upto($bp_large) {
            width: 452px;
            height: 356px;
            background-size: cover;
        }

        &--left {
            bottom: 0;
            left: -100px;
            transform: scaleX(-1) rotate(160deg);

            @include mq_upto($bp_large) {
                display: none;
            }
        }

        &--right {
            @include mq_for($bp_large) {
                top: -45px;
                right: -155px;
                transform: rotate(160deg);
            }

            @include mq_upto($bp_large) {
                top: 55px;
                right: -165px;
                transform: rotate(140deg);
            }
        }
    }
}


.home-cta {
    border: 2px solid;
    position: relative;
    z-index: 5; // higher than background picture (1)
    @include theme-border-color('border-donation');

    &:after {
        content: '';
        display: block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 3px;
        right: 3px;

        background: url('../images/cross.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 18px;
    }

    &__title {
        @include home-section-title();
        @include theme-text-color('text-body');
        margin-bottom: $base*4;
    }

    &__text {
        @include home-text-base();
    }

    &__button {
        margin-top: $base*6;
    }

    .home-button {
        @include gradient-button-base();
        width: 100%;
    }

    @include upto(large-screens) {
        border-radius: 13px;
        padding: $side_padding_mobile;

        &__title {
            margin-right: 100px;
        }
    }

    @include for(large-screens) {
        border-radius: 22px;
        padding: $base*5 $base*6;
    }
}


.home-section {
	position: relative;
    z-index: 10;

    border-top: 1px dashed;
    border-bottom: 1px dashed;
    @include theme-prop($prop--border-color, $key--primary);

	&__title {
		position: relative;
		z-index: 10;

		@include upto(large-screens) {
			margin-bottom: $base*5;
		}

		@include for(large-screens) {
			margin-bottom: $base*7;
		}
	}

    &__title-text { // it was extracted from &__title for the "Search form" in the "Authors slider"
        @include home-section-title();
    }

	&__content {
	}

	&--background {
		@include theme-prop($prop--background-color, $key--background-donation);
	}

	@include upto(large-screens) {
		padding: $base*8 0;
	}

	@include for(large-screens) {
		padding: $base*13 0 $base*14;

		&.mb-0 {
			padding-bottom: 0;
		}
	}
}


.home-counters {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;

	&__cell {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		color: $color-primary;

		& + & {
			margin-left: 2em;
		}

		.label {
			margin-left: 0.25em;
		}
	}

	@include upto(large-screens) {

		.number { 
			font-size: 32px; 
		}
		.label { 
			font-size: 23px; 
			letter-spacing: -0.01em;
		}
	}

	@include for(large-screens) {
		.number { 
			font-size: 54px; 
		}
		.label { 
			font-size: 40px; 
		}
	}
}


.home-trio {

	@include upto(large-screens) {
		&__cell {
			margin-bottom: $base*4;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@include for(large-screens) {
		display: grid;
		grid-gap: 90px;
		grid-template-columns: repeat(3, 1fr);
	}
}


.trio-cell {
	position: relative;


	&__number {
		position: absolute;
		font-weight: 300;
		background-color: $green;
	    background-image: linear-gradient(to left, $blue, $green);
	    background-size: 100%;
	    -webkit-background-clip: text;
	    -moz-background-clip: text;
	    background-clip: text;
	    -webkit-text-fill-color: transparent; 
	    -moz-text-fill-color: transparent;
	}

	&__title {
		@include h4-title();
    	@include theme-text-color('text-body');
    	margin-bottom: $base;

    	&:after {
    		content: '.';
    		color: $color-primary;
    		margin-left: 3px;
    		font-size: 1.5em;
    	}
	}

	@include upto(large-screens) {
		padding-left: 50px;

		&__number {
			font-size: 60px;
			line-height: 60px;
			top: 0;
			left: 0;
		}
	}

	@include for(large-screens) {
		padding-left: 78px;

		&__number {
			font-size: 100px;
			line-height: 90px;
			top: 0;
			left: 0;
			text-align: right;
		}

		&__title {
			padding-top: $base*4;
		}

		&:first-child {
			padding-left: 54px;
		}
	}
}

.home-authors-title {
    @include mq_for($bp_large) {
        display: flex;
    }

    &__form {
        @include mq_for($bp_large) {
            min-width: 400px;
            margin-left: 40px;
        }

        @include mq_between($bp_medium, $bp_large) {
            max-width: 400px;
            margin: 30px 0;
        }

        @include mq_upto($bp_medium) {
            max-width: 400px;
            margin: 30px auto;
        }
    }

    @include upto(medium-screens) {
        margin-bottom: 40px;
        text-align: center;
    }
}

.home-authors {
    position: relative;

    @include upto(medium-screens) {
        max-width: 350px;
        margin: 0 auto;
    }

    &__bg {
        position: absolute;
        z-index: 2;
        width: 100%;

        @include upto(large-screens) {
            top: -10px;

            .mobile {
                width: 100%;
                height: auto;
            }

            .full {
                display: none;
            }
        }

        @include for(large-screens) {
            top: -40px;

            .mobile {
                display: none;
            }

            .full {
                width: 100%;
                height: auto;
            }
        }
    }

    &__slider {
        position: relative;
        z-index: 10;
    }

    &__tags {
        margin-top: 40px;
    }
}



// FAQ section
.home-faq-2 {
    &__title {
        @include mq_upto($bp_medium) {
            margin-bottom: 40px;
        }

        @include mq_for($bp_medium) {
            margin-bottom: 58px;
        }

    }

    &__row {
        @include mq_for($bp_large) {
            display: flex;
        }
    }

    &__col1 {
        @include mq_for($bp_large) {
            width: 73%;
            margin-right: 5%;
        }

    }

    &__col2 {
        @include mq_for($bp_large) {
            width: 22%;
        }
    }

    &__more-link {
        margin-top: 28px;
    }

    &__banner {
        width: 285px;

        @include mq_upto($bp_large) {
            margin: 60px auto;
            //margin-left: auto;
            //margin-right: auto;
        }

        @include mq_between($bp_large, $bp_xxlarge) {
            width: 100%;
        }
    }

    &__extra {
        margin-top: 66px;
    }

    &__extra-title {
        margin-bottom: 25px;
        font: 600 21px/1 $ff-main;
    }

    &__extra-buttons {
    }

    &__extra-button {
        margin-right: 18px;

        &:last-child {
            margin-right: 0;
        }

        @include mq_upto($bp_medium) {
            margin-bottom: 15px;
        }
    }
}

// FAQ questions list
.term-list {
    @include mq_for($bp_large) {
        display: grid;
        grid-column-gap: 60px;
        grid-row-gap: 36px;
        grid-template-columns: repeat(2, 1fr);
    }

    &__item {
        border-top: 1px dashed #2bd7e0;
        padding-top: 30px;

        @include mq_upto($bp_large) {
            margin-top: 20px;

            &:nth-child(1) {
                margin-top: 0;
                border: none;
                padding-top: 0;
            }
        }

        @include mq_for($bp_large) {
            &:nth-child(1),
            &:nth-child(2) {
                border: none;
                padding-top: 0;
            }
        }
    }

    &__item-title {
        margin-bottom: 17px;

        font: 600 18px/1.3 $ff-main;
        letter-spacing: -0.01em;
    }

    &__item-text {
        font: 16px/22.4px $ff-main;
    }
}



// Journal articles list
.journal-articles {
    &__title {
        max-width: 650px;
        margin-bottom: 41px;

        @include mq_upto($bp_small) {
            font: 600 30px/36px $ff-main;
        }

        @include mq_for($bp_small) {
            font: 600 33px/39.6px $ff-main;
        }

        a {
            @include link-gradient();
        }
    }

    &__items {
        @include mq_for($bp_large) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__item {
        display: block;
        margin-bottom: 40px;

        @include mq_upto($bp_small) {

        }

        @include mq_for($bp_large) {
            width: 48%;
            margin-right: 3.5%;

            &:nth-child(even) {
                width: 48.5%;
                margin-right: 0;
            }
        }
    }
}
