/* card */

.campaign-card {
    text-align: left;

    &__preview {
        position: relative;  
    }

    &__preview-link {
        display: block;
        position: relative;
        z-index: 2;

        img {
            width: 100%;
            height: auto; 
            z-index: 3;
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
        }
    }

    &--finished &__preview-link:after {
        background: transparentize($color-primary, 0.9);
    }

    &--closed &__preview-link:after {
        background: transparentize($brown, 0.8);
    }

    &__scale {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 10;

        .scale {
            height: 6px;
            border-radius: 0;
            background: transparent;

            &__progress {
                border-radius: 0;
            }
        }
    }

    &__content {
        margin-top: $base*1.5;
    }

    &__content-link {
        color: inherit;
    }

    &__title {
        font-weight: 600;
        line-height: 1.35;

        @include upto(large-screens) {
            font-size: 15px;
        }

        @include for(large-screens) {
            font-size: 16px;
        }
    }

    &__description {
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.01em;
        margin-top: $base*0.5;
    }
}
