/* Basic layout and grid rules */

// Containers 
@mixin container-base {
    position: relative;
    margin-left: auto; 
    margin-right: auto;


    @media screen and (max-width: 385px) {
        width: calc(100% - 40px);
    }

    @media screen and (min-width: 386px) and (max-width: $two - 1) {
        width: calc(100% - 50px);
    }

    @include between(small-screens, large-screens) {
        width: calc(100% - 60px);
        max-width: 640px;
    }

    @include for(large-screens) {
        width: 92%;
    }
}

.container {
    @include container-base();
    max-width: $full_cont;

    .container & { width: 100%; }
}

.container-text {
    @include container-base();
    max-width: $text_cont; 

    .container & { width: 100%; }
}

.container-semi {
    @include container-base();
    max-width: $semi_cont;

    .container & { width: 100%; }
}

.text-column {
    max-width: $text_cont;
}

.semi-column {
    max-width: $semi_cont;
}



// two columns base  
.content-columns {
    display: flex;

    a {
        @include link-color($color-link);
    }

    &__narrow {
        max-width: 600px;
    }
    
    @include upto(large-screens) {
        flex-flow: column;
        justify-content: flex-start;

        &__main {
            order: 2;
        }

        &__side {
            order: 1;
        }

    }

    @include for(large-screens) {
        flex-flow: row;
        justify-content: space-between;

        &__main {
            width: 65%;
            &--wide {
                width: 76%;
            }
        }

        &__side {
            width: 28%;
        }

    }
}
