/* Minimal reset for crossbrowser compatibility */
/* Only items actually used in markup presented */
*,
*:after,
*:before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: 0;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a,
abbr, acronym, address, big, cite, code, del, dfn, em,
img, ins, q, s, samp, small, strike, strong, sub, sup, var,
b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, embed, figure, figcaption, footer, header, hgroup,  menu, nav, section,
time, audio, video {
	vertical-align: baseline;
}

ol, ul {
	list-style: none;
}

html {
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	background: #fff;
}

// Link states
a {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover,
a:active {
	outline: 0;
	outline: none;
}

// HTML5
article,
aside,
figcaption,
figure,
footer,
header,
nav,
section,
iframe {
  display: block;
}

// Imgs
img {
	border: 0;
	-ms-interpolation-mode: bicubic;
	vertical-align: bottom;
	max-width: 100%;
	height: auto;
}

img[alt] {
	font-size: 10px;
	line-height: inherit;
	color: #666;
	font-family: inherit;
}

a img {
	border: 0;
	vertical-align: middle;
}

svg:not(:root) {
	overflow: hidden; }



// Forms -- TODO
button,
input,
select,
textarea {
	font-size: 100%; /* Corrects font size not being inherited in all browsers */
	margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
	vertical-align: baseline; /* Improves appearance and consistency in all browsers */
	*vertical-align: middle;
}

button,
input {
  line-height: normal; // FF3/4 have !important on line-height in UA stylesheet
  *overflow: visible; // Inner spacing ie IE6/7
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */
	cursor: pointer;
}


input[type="checkbox"],
input[type="radio"] {
	padding: 0; /* Addresses excess padding in IE8/9 */
}

input[type="search"]::-webkit-search-decoration { /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
	-webkit-appearance: none;
}

input[type=search] {
   -moz-appearance:none;
   -webkit-appearance:none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;  
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }

/* Corrects inner padding and border displayed oddly in FF3/4
   www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {	
	outline: none;
}


textarea {
	overflow: auto; /* Removes default vertical scrollbar in IE6/7/8/9 */
	vertical-align: top; /* Improves readability and alignment in all browsers */
	width: 100%;
}

