/** Colors */

@use 'sass:list';


/* CSS properties that are changed in light/dark themes */
$prop--color: "color";
$prop--background-color: "background-color";
$prop--border-color: "border-color";
$prop--fill: "fill";
$prop--stroke: "stroke";
$prop--box-shadow: "box-shadow";


/* Keys (used in $colors var for now, but meant to be used anywhere) */
$key--text-body: "text-body";
$key--text-semi: "text-semi";
$key--text-light: "text-light";
$key--error: "error";
$key--success: "success";

$key--background-body: "background-body";
$key--background-tint: "background-tint";
$key--background-dark: "background-dark";
$key--background-slight: "background-slight";

// donation form
$key--background-donation: "background-donation";
$key--border-donation: "border-donation";
$key--border-donation-field: "border-donation-field";
$key--scale-base: "scale-base";
$key--background-popup: "background-popup";
$key--background-option: "background-option";
$key--background-donation-field: "background-donation-field";

// account form
$key--border-regular-base: "border-regular-base";
$key--border-regular-light: "border-regular-light";
$key--border-neutral: "border-neutral";
$key--background-field-base: "background-field-base";
$key--background-field-focus: "background-field-focus";
$key--background-side-block: "background-side-block";

$key--background-placeholder: "background-placeholder";
$key--border-placeholder: "border-placeholder";
$key--border-placeholder-light: "border-placeholder-light";
$key--text-placeholder: "text-placeholder";

// accente
$key--link: "link";
$key--primary: "primary";



// Base
$blue:      #11A4D7;
$green:     #2BD7E0;
$green2:    #25C7CF;
$dark:      #212629;

$beige:     #F0EBE4;
$beige2:    #B7AC9B;
$brown:     #B79E75;
$red:       #F7855A;

$white:     #fff;
$black:     #000;
$black2:    #171C1F;
$gray-base: #E8E9EA;


// Context names (backward)
$color-primary:     $green;
$color-link:        $blue;
$color-white:       #fff;
$color-black:       $black;
$color-grey:        $gray-base;
$color-brown:       $brown;

// TODO
$color-text:            $gray-base;
$color-text-semi:       $gray-base;
$color-text-light:      $gray-base;
$color-border-regular:  $gray-base;
$color-border-light:    #929BA1;
$color-bg-light:        $gray-base;


$color-blue-2:  #0088cc;
$color-blue-light:  #ccdffc;
$color-red-light:   #FFD6DD;
$color-green-light: #D6FAEA;


// statuses colors
$statuses_colors: (
    'draft': #DDD6CC,
    'rejected': #F7C2AE,
    'pending': #99d2eb,
    'launched': $green,
    'closed': $brown,
    'finished': $blue,
    'banned': $red,
    'approved': $green,
);


$colors: (
    #{$key--text-body}:  [$black, $white],
    #{$key--text-semi}:  [$dark, transparentize($white, 0.2)],
    #{$key--text-light}: [#9a9a9a, #9a9a9a],
    #{$key--error}:      [$red, $red],
    #{$key--success}:    [$green, $green],

    #{$key--background-body}:   [$white, $dark],
    #{$key--background-tint}:   [$gray-base, darken($dark, 5%)],
    #{$key--background-dark}:   [$dark, $dark],
    #{$key--background-slight}: [#f9f9f9, #292e32],

    #{$key--background-donation}:   [#FAF8F6, #2A2E30],
    #{$key--border-donation}:       [#B7AC9B, #B7AC9B],
    #{$key--border-donation-field}: [#D8D1C8, #646665],
    #{$key--scale-base}:            [#E0D9CF, #5C5F5F],
    #{$key--background-popup}:      [#F3EFEC, #2C3133],
    #{$key--background-option}:     [#EEE8E0, #4A4B4A],
    #{$key--background-donation-field}: [rgba(255,255,255, 0.05), #272D30],

    #{$key--border-regular-base}:    [$dark, $white],
    #{$key--border-regular-light}:   [$color-border-light, $white],
    #{$key--border-neutral}:         [transparentize($dark, 0.75), transparentize($white, 0.75)],
    #{$key--background-field-base}:  [#f9f9f9, $dark],
    #{$key--background-field-focus}: [$white, transparentize($white, 0.9)],
    #{$key--background-side-block}:  [#F5F0EA, #414546],

    #{$key--background-placeholder}:   [transparentize($blue, 0.9), transparentize($blue, 0.8)],
    #{$key--border-placeholder}:       [transparentize($blue, 0.6), transparentize($blue, 0.65)],
    #{$key--border-placeholder-light}: [transparentize($blue, 0.9), transparentize($blue, 0.9)],
    #{$key--text-placeholder}:         [transparentize($dark, 0.6), transparentize($gray-base, 0.5)],

    #{$key--link}:    [$blue, $blue],
    #{$key--primary}: [$green, $green],
);


@mixin theme-prop($prop_name, $key) {
    @media (prefers-color-scheme: dark) {
        #{$prop_name}: dark_color($key);
    }
    @media (prefers-color-scheme: light) {
        #{$prop_name}: light_color($key);
    }
}

@function light_color($key) {
    @if map-has-key($colors, $key) {
        $_colors: map-get($colors, $key);
        @return list.nth($_colors, 1);
    }

    @error "Unknown '#{$key}' in $colors.";
}

@function dark_color($key) {
    @if map-has-key($colors, $key) {
        $_colors: map-get($colors, $key);
        @return list.nth($_colors, 2);
    }

    @error "Unknown '#{$key}' in $colors.";
}


@mixin for-dark-theme() {
    @media (prefers-color-scheme: dark) { @content; }
}

@mixin for-light-theme() {
    @media (prefers-color-scheme: light) { @content; }
}

@mixin light_dark($prop, $value_light, $value_dark) {
    @media (prefers-color-scheme: light) {
        #{$prop}: $value_light;
    }

    @media (prefers-color-scheme: dark) {
        #{$prop}: $value_dark;
    }
}


// Gradients
@mixin brand-gradient() {
    background: linear-gradient(to left, $blue, $green);
}

@mixin brand-gradient-reversed() {
    background: linear-gradient(to left, $green, $blue);
}


@mixin brand-gradient-vertical() {
    background: linear-gradient(to bottom, $green, $blue);
}

@mixin link-gradient() {
    // https://fossheim.io/writing/posts/css-text-gradient/
    background-color: $blue;
    background-image: linear-gradient(270deg, $blue 0%, $green 100%);
    background-size: 100%;
    -webkit-background-clip: text; // chrome
    background-clip: text; // firefox
    -webkit-text-fill-color: transparent; // chrome,firefox
}
