// basic styles 
@mixin dform-error-text() {
    color: $red;
    font-size: 70%;
    padding: 3px 0 0 9px;
}



.donation-form {

    &__error {
        text-align: center;

        @include upto(medium-screens) {
            padding-bottom: $base*2;
        }

        @include for(medium-screens) {
            padding-bottom: $base*4;
        }

        .alert {
            background: transparent;
            padding:0;
            margin: 0;
        }
    }

    &.payment-open {
        * { opacity: 0; }
    }

    &.loading {
        opacity: 0.5;
    }

    &__amount {
        @include upto(medium-screens) {
            padding: 0 $side_padding_mobile;
        }

        @include for(medium-screens) {
            padding: 0 $side_padding_desktop;
        }
    }

    &__fields {
        margin: 8px 0;
    }

    &__disclaymer {
        padding: $base*2 0;
    }

    &__submit {
        margin-top: $base;
    }
}


.dform-submit {
    @include gradient-button-base();
    width: 100%;

    @include mq_upto($bp_355) {
        padding-left: 14px;
        padding-right: 14px;
    }
}

.dform-toggle {
    
    &__close {
        padding-top: $base*2;
        margin-bottom: -3px;
        cursor: pointer;
    }
}

.dform-period {
    margin-bottom: $base*6; 
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    &__option + &__option {
        margin-left: $base;
    }

    &__option {
        position: relative;

        input {
            position: absolute;
            opacity: 0;
            z-index: -1;
        }

        label {
            display: block;
            text-transform: uppercase;
            text-align: center;
            font-weight: 500;
            font-size: 12px;
            line-height: 27px;

            height: 27px;
            border-radius: 15px;
            transition: background .45s;
            cursor: pointer;

            @include theme-background-color('background-option');

            @include mq_upto($bp_355) {
                width: 120px;
            }

            @include mq_for($bp_355) {
                width: 140px;
            }
        }

        input:checked + label {
            @include brand-gradient();
            color: $color-white;
            cursor: default;
        }
    }
}


.dform-amount {
    display: flex;
    flex-flow: row wrap;
    gap: 7px;
    width: 100%;
    position: relative;
    bottom: -3px;

    &__cell {
        width: calc(25% - 6px);
    }

    &__error {
        width: 100%;
        @include dform-error-text();
        position: absolute;
        bottom: 0;
        transform: translateY(105%);
    }
}

.amount-fix {
    position: relative;
    text-align: center;

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    label {
        display: block;
        font-weight: 500;
        width: 100%;
        text-align: center;
        padding-bottom: 6px;
        position: relative;
        transition: color .25s;
        height: 32px;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 6px;
            border-radius: 3px;
            @include theme-background-color('scale-base');
            position: absolute;
            bottom: 0;
            transition: background .25s;
        }

        &.active {
            color: $color-primary;
            font-weight: 600;
            &:after {
                background-color: $color-primary;
            }
        }
    }
}

.amount-flex {
    position: relative;
    height: 32px;

    // underline
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 6px;
        border-radius: 3px;
        @include theme-background-color('scale-base');
        position: absolute;
        bottom: 0;
        transition: background .25s;
        z-index: 100;
    }

    &--active:after {
        background-color: $color-primary;
    }

    &__trigger {  // input:radio wrapper
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 13px;
        color: $brown;
        text-align: center;
        text-transform: lowercase;

        input {  // input:radio to trigger "focus" state
            position: absolute;
            opacity: 0;
            z-index: -1;
        }

        label {
            @include upto(small-screens) {
                display: inline-block;
                max-width: 70px;
                position: relative;
                left: 0;
                top: -4px;
                line-height: 1;
            }

            @include for(small-screens) {
                white-space: nowrap;
            }
        }
    }

    &__input {  // input wrapper
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        input {  // input
            width: 100%;
            text-align: center;
            font-weight: 500;
            font-family: $text;
            @include theme-text-color('text-body');
            font-size: 14px;
            padding-bottom: 7px;
            padding-top: 4px;
            @include theme-background-color('background-donation');
            border: none;
            border-radius: 3px;
            outline: none;
        }
    }
}

[data-flextip] {

    $triangle: 6px;
    $background-color: $dark;

    cursor: pointer;
    position: relative;

    &::after {
        content: attr(data-flextip);
        display: inline-block;
        
        background-color: $background-color;
        border-radius: 3px;
        color: $color-white;
        
        font-size: 11px;
        line-height: 12px;
        text-align: center;
        text-transform: none;
        white-space: nowrap;

        // top
        position: absolute;
        padding: 3px 6px;
        right: 0;
        top: 0;
        transform: translateY(-130%);
        opacity: 0;
        transition: opcaity .35s;
    }

    &::before {
        border-style: solid;
        border-width: $triangle;
        border-color: $background-color transparent transparent transparent;
        content: "";
        position: absolute;
        top: -6px;
        left: 50%;
        margin-left: -$triangle;
        opacity: 0;
        transition: opcaity .35s;
    }

    &.flextip-show {
        &::after,
        &::before {
            opacity: 1;
        }
    }
}


.dform-group {
    position: relative;

    label {
        font-size: 14px;
        line-height: 30px;
        text-transform: lowercase;
        font-weight: 600;
        letter-spacing: -0.02em;
        width: 78px;
        position: absolute;
        left: 14px;
        top: 6px;
    }

    input {
        width: 100%;
        border: 1px solid;
        @include theme-border-color('border-donation-field');
        //@include theme-background-color('background-donation-field');
        background: transparent;
        @include theme-text-color('text-body');

        font-size: 14px;
        line-height: 30px;
        padding: $base $base $base 92px;
        border-radius: 25px; 

        &:focus {
            @include theme-background-color('background-body');
            @include theme-border-color('border-regular-base');
        }
    }

    &__error {
        @include dform-error-text();
    }

    &.invalid input {
        border-color: $red;
    }

    &.valid input {
        border-color: $green;
    }
}

.dform-group + .dform-group {
    margin-top: 12px;
}

// Pretty switch/checkbox (initially for "I have a foreign bank card" field in the Donation form)
// see: https://www.youtube.com/watch?v=wQVkA6cOBiI
.dform-group2 {
    margin-top: 12px;
}

.pretty-checkbox {
    &__label {
        display: flex;
        align-items: center;

        padding: 12px 13px;

        border-radius: 25px;
        cursor: pointer;

        @include theme-background-color('background-option');
        opacity: 0.88;
        // background-color: rgba(238, 232, 224, 0.88); // dark rgb(125 117 107 / 88%)
    }

    &__items {
    }

    &__input {
        display: none;
    }

    &__visual-input {
        display: block;
        width: 40px;
        height: 20px;
        position: relative;

        border-radius: 100px;
        transition: background 0.2s;
        @include for-light-theme() {
            background-color: #ccc6bd;
        }
        @include for-dark-theme() {
            background-color: #9b968e;
        }

        &:after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            position: absolute;
            top: 6px;
            left: 6px;
            background-color: #fff;
            border-radius: 50%;
            transition: transform 0.2s;
        }
    }

    &__input:checked ~ &__visual-input {
        background-color: $green;
    }

    &__input:checked ~ &__visual-input::after {
        transform: translateX(20px);
    }

    &__text {
        margin-left: 20px;

        @include theme-text-color('text-body');
        font: 14px/1 $text;
        text-transform: lowercase;
        user-select: none;
    }
}


.dform-agree {
    .dform-check + .dform-check {
        margin-top: $base*2;
    }
}

.dform-check {
    font-size: 10px;
    line-height: 1.4;
    position: relative;
    padding-left: 34px;

    &__error {
        color: $red;
        margin-top: 3px;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    &__input {
        position: absolute;
        opacity: 0;
    }

    &__label {
        display: flex;
        align-items: center;
        min-height: 25px; // 18px + 3.5px (top margin) + 3.5px (mirrored bottom margin)   see "top" prop of :before elem
        position: relative;
        pointer-events: all;

        &:before {
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: transparent;
            border: 1px solid $brown;
            position: absolute;
            top: 3.5px;
            left: -27px;
            cursor: pointer;
        }
    }

    &__label-text {
        display: inline-block;
    }

    &__input:checked + &__label {
        &:before {
            border-color: $color-primary;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxMHB4IiB2aWV3Qm94PSIwIDAgMTAgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+UGF0aDwvdGl0bGU+CiAgICA8ZyBpZD0iZnJvbS11cGRhdGUiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIzMC1BdXRob3ItZm9ybS0tY29taXNzaW9uMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTgyOC4wMDAwMDAsIC00ODEuMDAwMDAwKSIgZmlsbD0iIzJCRDdFMCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik04MzEuODQ3OTI2LDQ5MC44NTAyMyBDODMyLjIzOTYzMSw0OTAuODUwMjMgODMyLjU1MDY5MSw0OTAuNjY5NzM5IDgzMi43ODExMDYsNDkwLjMwODc1NiBMODM3LjczNTAyMyw0ODIuNTc4MzQxIEM4MzcuNzk2NDY3LDQ4Mi40NjMxMzQgODM3Ljg1NTk5MSw0ODIuMzQ0MDg2IDgzNy45MTM1OTQsNDgyLjIyMTE5OCBDODM3Ljk3MTE5OCw0ODIuMDk4MzEgODM4LDQ4MS45NzU0MjIgODM4LDQ4MS44NTI1MzUgQzgzOCw0ODEuNTk5MDc4IDgzNy45MDIwNzQsNDgxLjM5MzYyNSA4MzcuNzA2MjIxLDQ4MS4yMzYxNzUgQzgzNy41MTAzNjksNDgxLjA3ODcyNSA4MzcuMjkzMzk1LDQ4MSA4MzcuMDU1Myw0ODEgQzgzNi43MzI3MTksNDgxIDgzNi40NjAwNjEsNDgxLjE3NjY1MSA4MzYuMjM3MzI3LDQ4MS41Mjk5NTQgTDgzMS44MTMzNjQsNDg4LjYwMzY4NyBMODI5LjczOTYzMSw0ODUuOTMwODc2IEM4MjkuNjA5MDYzLDQ4NS43Njk1ODUgODI5LjQ3NjU3NSw0ODUuNjU2Mjk4IDgyOS4zNDIxNjYsNDg1LjU5MTAxNCBDODI5LjIwNzc1Nyw0ODUuNTI1NzMgODI5LjA2Mzc0OCw0ODUuNDkzMDg4IDgyOC45MTAxMzgsNDg1LjQ5MzA4OCBDODI4LjY0OTAwMiw0ODUuNDkzMDg4IDgyOC40MzIwMjgsNDg1LjU4MzMzMyA4MjguMjU5MjE3LDQ4NS43NjM4MjUgQzgyOC4wODY0MDYsNDg1Ljk0NDMxNiA4MjgsNDg2LjE2MTI5IDgyOCw0ODYuNDE0NzQ3IEM4MjgsNDg2LjY1Mjg0MiA4MjguMDkyMTY2LDQ4Ni44OTA5MzcgODI4LjI3NjQ5OCw0ODcuMTI5MDMyIEw4MzAuODgwMTg0LDQ5MC4zMDg3NTYgQzgzMS4wMjYxMTQsNDkwLjUwMDc2OCA4MzEuMTc1ODgzLDQ5MC42MzkwMTcgODMxLjMyOTQ5Myw0OTAuNzIzNTAyIEM4MzEuNDgzMTAzLDQ5MC44MDc5ODggODMxLjY1NTkxNCw0OTAuODUwMjMgODMxLjg0NzkyNiw0OTAuODUwMjMgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
            background-repeat: no-repeat;
            background-size: 10px 10px;
            background-position: 3px 3px;
        }
    }

    &.invalid &__label:before {
        border-color: $red;
    }
}
