/* temp blocks */


.temp-campaign-banner {
    width: 100%;
    background: #e9e9e9; 

    @include upto(medium-screens) {
        height: 220px;
    }

    @include for(medium-screens) {
        max-width: 700px;
        height: 420px;
    }
}
