$campaign_container-horizontal-margin-on-mobiles: -20px;

.campaign {

    &__semibottom {
        margin-top: $base*10;
    }

    &__bottom {
        margin-top: $base*15;
    }

    &__qr {
        margin-top: $base*10;
    }

    @include upto(large-screens) {
        &__side {
            margin: $base*6 0; 
        }
    }

    @include for(large-screens) {
        &__row {
            display: grid;
            grid-gap: 60px; 
            grid-template-columns: 1fr 436px;
            //column-gap: 90px;
            //row-gap: 45px;
        }

        &__head {
            grid-column: 1;
            grid-row: 1;
        }

        &__side {
            grid-column: 2;
            grid-row: 1 / span 4;
        }

        &__content {
            grid-column: 1;
        }

        &__semibottom {
            grid-column: 1;
        }

        &__bottom {
            grid-column: 1;
        }
    }
}

.campaign-head {

    @include upto(medium-screens) {

        &__preview {
            margin-bottom: $base*3; 
            margin-left: $campaign_container-horizontal-margin-on-mobiles;
            margin-right: $campaign_container-horizontal-margin-on-mobiles;
        }

        &__mobile-head {
            margin-bottom: $base*3; 
        }
    }

    @include between(medium-screens, large-screens) {

        &__preview {
            margin-bottom: $base*3; 
        }

        &__mobile-head {
            margin-bottom: $base*3; 
        }
    }

    @include for(large-screens) {
        &__mobile-head {
            display: none;
        }

        &__preview {
            margin-bottom: $base*6; 
        }

        &__author {
            padding-left: 36px;
        }
    }
}

.campaign-donation {

    &__error {
        @include theme-background-color('background-placeholder');
        border-top: 1px solid;
        border-bottom: 1px solid;
        @include theme-border-color('border-placeholder');
        padding: $base*2;
        margin-top: $base*6;
    }

    @include upto(medium-screens) {
        &__desktop-head {
            display: none;
        }
    }

    @include between(medium-screens, large-screens) {
        &__support {
            overflow: hidden;
        }

        &__desktop-head {
            display: none; 
        }
    }

    @include for(large-screens) {
        position: sticky;
        top: 90px;
        
        &__desktop-head {
            margin-bottom: $base*6;
        }
    }
}

.campaign-more {

    &__title {
        @include h3-title();
        margin-bottom: $base*4;
    }
}


