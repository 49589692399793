/* Buttons */

// also see _mixins.scss

@mixin button-gradient-grey() {
    @include for-light-theme {
        background-image: linear-gradient(90deg, #E1E1E1 0%, #C3C3C3 96.88%);
    }
    @include for-dark-theme {
        background: linear-gradient(90deg, #5A5A5B 0%, #7D7D7D 96.88%);
    }
}

@mixin gradient-button-small {
    display: inline-block;
    text-transform: lowercase;
    text-align: center;

    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    padding: 6px 24px;
    border-radius: 18px;

    @include button-gradient-with-hover();
}

.small-gradient {
    @include gradient-button-small();
}


@mixin gradient-button-base {
    display: inline-block;
    text-transform: lowercase;
    text-align: center;

    font-family: $text;
    font-size: 1.1em;
    font-weight: 500;
    line-height: 24px;
    padding: 14px 24px;
    border-radius: 25px;

    @include button-gradient-with-hover();
}

.base-gradient {
    @include gradient-button-base();
}


.form-submit, .dfp-submit-button {
    @include gradient-button-base();

    width: 280px;

    &--wide {
        width: 100%;
    }

    @media screen and (max-width: 360px) {
        width: 100%;
    }
}


@mixin aside-button-base() {
    display: inline-block;
    @include sitenav-link();
    background: transparent;
    padding: 4px 14px;
    border-radius: 16px;

    border: 1px solid $brown;
    color: $brown;
    text-align: center;
    transition: background .35s;

    &:hover {
        @include theme-background-color('background-donation');
    }
}


@mixin secondary-action-button() {
    display: inline-block;
    @include form-text();
    text-transform: lowercase;
    background: transparent;
    line-height: 20px;
    padding: 4px 14px;
    border-radius: 16px;

    border: 1px solid $green;
    color: $green;
    text-align: center;
    transition: background .35s;

    &:hover {
        background: transparentize($green, 0.9);
    }
}


.form-aside {
    @include aside-button-base();
    width: 220px;
    
    &--wide {
        width: 100%;
        padding: 6px 14px;
        border-radius: 18px;
    }
}


@mixin utility-button-base() {
    display: inline-block;
    font-size: 14px;
    text-transform: lowercase;
    padding: 4px 14px;
    border: 2px solid $color-primary;
    color: $color-primary;
    text-align: center;
    border-radius: 24px;
    transition: background .35s;

    &:hover {
        background: transparentize($color-primary, 0.8);
    }
}

.form-utility {
    @include utility-button-base();
}


.button1 {
    @include gradient-button-base();

    &--disabled {
        @include button-gradient-grey();
        cursor: default;

        @include light_dark(color, $color-white, transparentize($color-white, 0.8))
    }

    &--width-default {
        width: 280px;
    }
}
