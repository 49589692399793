@mixin form-error-text() {
    color: $red;
    @include ultra-small-text();
}

@mixin form-help-text() {
    @include ultra-small-text();
    @include theme-text-color('text-light');
    padding-left: 3px;

    a {
        color: $color-link;
    }
}

@mixin form-prefix-text() {
    @include theme-text-color('text-light');
    line-height: 42px;
}

@mixin form-field-input-text() {
    font-family: $text;
    font-size: 1em;
    line-height: 24px;
    font-weight: 500;
    @include theme-text-color('text-body');
}

@mixin form-field-border-color() {
    @include for-dark-theme() {
        $base: dark_color('border-regular-light');
        border-color: transparentize($base, 0.5);
        transition: all .35s;

        &:focus:not([readonly]) {
            border-color: transparentize($base, 0.05);
        }
    }

    @include for-light-theme() {
        $base: light_color('border-regular-light');
        border-color: transparentize($base, 0.5);

        &:focus:not([readonly]) {
            border-color: $base;
        }
    }
}

@mixin form-field-border() {
    border: 1px solid;
    border-radius: 6px;

    @include form-field-border-color();
}
