
/*
The header with a blue border on the left.
Used in: form section title, page section header in Author dashboard
 */
.header-style1 {
    @include h3-title();

    display: flex;
    align-items: end;
    margin-bottom: $base*5;
    text-transform: none;

    &::before {
        content: '';
        display: inline-block;
        margin-right: 25px;
        width: 7px;
        height: 24px;

        border-radius: 3px;
        background-color: $color-primary;
    }
}

/*
The header with a grey border on the left (smaller than .header-style1)
Used in: form section title
 */
.header-style2 {
    @include h3-title();
    font-size: 16px; // initial 18/22

    display: flex;
    align-items: end;
    margin-bottom: $base*5;
    text-transform: none;

    &::before {
        content: '';
        display: inline-block;
        margin-right: 25px;
        width: 7px;
        height: 24px;

        border-radius: 3px;
        @include theme-background-color('scale-base');
    }
}
