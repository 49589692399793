
.embla-authors {
    position: relative;

    &__viewport {
        overflow: hidden;
        width: 100%;
        z-index: 10; 
    }

    &__container {
        display: flex;
        user-select: none;
        width: 100%;
    }

    &__slide {
        position: relative;

        @include upto(medium-screens) {   
            min-width: 100%;
            padding: 0 40px;
        } 

        @include between(medium-screens, large-screens) {   
            min-width: 50%;
            padding: 0 40px;
        }

        @include for(large-screens) {
            $item-width: 20%; // "emla" slider doesn't understand "width" prop for some reason :(
            min-width: $item-width;
            max-width: $item-width;

            padding: 0 15px;
        }
    }

    &__slide-inner {
        position: relative;
        overflow: hidden;
        min-height: 300px;
    }

    &__button-row {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        position: absolute;
        
        z-index: 5; 
        left: 50%;
        
        transform: translateX(-50%);

        @include upto(medium-screens) { 
            width: calc(100% + 60px);
            top: 140px;
        }

        @include between(medium-screens, large-screens) { 
            width: calc(100% + 90px);
            top: 120px;
        }

        @include for(large-screens) {
            width: calc(100% + 90px);
            top: 160px;
        }
    }

    &__button {
        width: 36px;
        height: 36px;
        background: transparent;
        cursor: pointer;
        transition: background .35s;

        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 0;

        &:disabled {
            cursor: default;
            opacity: 0.3;
        }

        .svg-icon {
            width: 36px;
            height: 36px;
            fill: $color-primary
        }

        &--prev {
            left: 0;

            .svg-icon {
                transform: rotate(180deg);
            }
        }

        &--next {
            right: 0;
        }
    }
}


.author-card {

    &__link {
        display: block;
    }

    &__preview {
        position: relative;
        text-align: center;
        padding: 8px;
        border: 1px solid $color-primary;
        border-radius: 50%;
        @include theme-background-color('background-body');

        img {
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
            border-radius: 50%;
        }

    }

    .author-avatar__placeholder {
        aspect-ratio: 1 / 1;

    }

    &__name {
        margin-top: $base*5;
        @include h4-title();
        @include theme-text-color('text-body');
        text-align: center;
    }

    &__description {
        margin-top: $base;
        @include home-text-base();
        @include theme-text-color('text-body');
        text-align: center;
        padding: 0 10px;

        @include upto(small-screens) {
            padding: 0;
        }
    }
}
