

.author-support {
    border: 2px solid;
    @include theme-border-color('border-donation');

    @include upto(small-screens) {
        margin: 0 -10px;
    }

    @include between(small-screens, medium-screens) {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    @include upto(large-screens) {
        border-radius: 12px;
    }

    @include for(large-screens) {
        position: sticky;
        top: 125px;
        border-radius: 24px;
    }

    &__title {
        @include h4-title();
        text-align: center;
        @include theme-text-color('text-body');
        
        @include upto(medium-screens) {
            padding: $base*4 $base*2;
        }

        @include between(medium-screens, large-screens) {
            padding: $base*3 $base*5 $base*5;
        }

        @include for(large-screens) {
            padding: $base*5 $base*5 $base*4;
        }
    }

    .donation-form__toggle {
        @include theme-background-color('background-donation');
        
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @include upto(medium-screens) {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: $side_padding_mobile $side_padding_mobile $side_padding_mobile;
        }

        @include between(medium-screens, large-screens) {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: $side_padding_mobile $side_padding_mobile $side_padding_mobile;
        }

        @include for(large-screens) {
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;
            padding: $side_padding_desktop $side_padding_desktop $side_padding_desktop;
        }
    }
}


