// Used for dead authors
.card1 {
    max-width: 470px;
    border-style: solid;
    @include theme-border-color('border-donation');
    padding: 40px 10px 20px;

    font-weight: 600;
    text-align: center;
    @include theme-background-color('background-donation');
    border-radius: 24px;

    &--style-tiny {
        min-height: 150px;
        border-width: 1px;

        font-size: 17px;
        line-height: 23.4px;
        letter-spacing: -0.01em;
    }

    &--style-base {
        min-height: 290px;
        border-width: 2px;

        font-size: 21px;
        line-height: 27.3px;
        letter-spacing: -0.16px;
    }

    &--pos-mobile {
        @include mq_upto($bp_large) {
            margin-top: 30px;
            margin-bottom: 50px;

        }

        @include mq_for($bp_large) {
            display: none;
        }
    }

    &--pos-desktop {
        @include mq_upto($bp_large) {
            display: none;
        }

        @include mq_for($bp_large) {
        }
    }
}


// Used in "Journal articles" block on Main page
.card2 {
    @include mq_for($bp_small) {
        display: grid;
        grid-template-columns: 210px minmax(100px, 1fr);
        grid-template-rows: min-content minmax(100px, 1fr);
        grid-template-areas:
            "thumb tag"
            "thumb title";
    }

    &__thumb-wrapper {
        @include mq_upto($bp_small) {
            margin-bottom: 20px;
        }

        @include mq_for($bp_small) {
            grid-area: thumb;
        }
    }

    &__thumb {
        margin-right: 20px;
        border-radius: 10px;
        overflow: hidden;
    }

    &__tag {
        padding-bottom: 13px;

        @include light_dark($prop--color, $black2, transparentize($white, 0.1));
        font: 12px/1 $ff-main;
        letter-spacing: -0.01em;

        @include mq_between($bp_small, $bp_large) {
            padding-top: 4px;
        }

        @include mq_for($bp_small) {
            grid-area: tag;
            padding-left: 20px;
        }
    }

    &__title {
        @include theme-prop($prop--color, $key--text-body);
        font: 500 18px/21.6px $ff-main;
        letter-spacing: -0.01em;

        @include mq_for($bp_small) {
            grid-area: title;
            padding-left: 20px;
        }
    }
}
