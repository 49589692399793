/* alerts*/
@mixin alert-base() {
    padding: $base*2 $base*4;
    border-radius: 3px;
    margin: $base 0;
    font-family: $text;
    font-size: 12px;
    font-weight: 500;
}

.alert, .dfp-message {
    @include alert-base();

    &.error, 
    &--error {
        color: $red;
        background: transparentize($red, 0.9);
    }

    &.success, 
    &--success,
    .dfp-message--success {
        color: $green;
        background: transparentize($green, 0.9);
    }

    &.info,
    &--info {
        color: $blue;
        background: transparentize($blue, 0.9);
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
}

.alert2 {
    padding: 23px 25px 25px 55px;
    position: relative;

    border-radius: 12px;
    @include theme-background-color('background-donation');

    &__icon {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 16px;
        height: 16px;

        svg {
            width: 20px;
            height: 20px;

            overflow: visible !important;
            fill: #25C7CF;
        }
    }

    &__body {
        font-weight: 500;
        font-size: 16px;
        line-height: 19.5px;
        letter-spacing: -0.01em;
    }
}

.info-text {
    border-radius: 3px;

    .utility {
        @include utility-link();
        font-size: 0.95em;
    }
    
    &.success {
        padding: $base*2 $base*4;
        color: $green;
        background: transparentize($green, 0.9);
        font-weight: 500;
    }

    &.info {
        padding: $base*2 $base*4;
        color: $blue;
        background: transparentize($blue, 0.9);
        font-weight: 500;
    }

    &.warning {
        padding: $base*2 $base*4;
        color: $red;
        background: transparentize($red, 0.9);
        font-weight: 500;
    }

    ul {
        margin-top: 1em;
        margin-bottom: 1em;
        padding-left: 10px;
    }
    li {
        list-style-type: disc;
        list-style-position: inside;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
}

.warning {
    color: $red;
    a {
        color: inherit;
        text-decoration: underline;
    }
}

.empty-message {
    p + p {
        margin-top: $base*2;
    }

    a {
        color: $color-link;
    }
}


// in preview
.help-message {
    @include theme-background-color('background-placeholder'); 
    border-left: 4px solid;
    @include theme-border-color('border-placeholder');
    padding: $base*2 $base*4;
    border-radius: 3px;

    font-size: 14px;
    line-height: 1.5;
    @include theme-text-color('text-semi');

    a {
        @include utility-link();
        text-decoration: underline;
        margin-left: $base;
    }
}


.preview-panel {
    @include theme-background-color('background-placeholder');
    border-top: 1px solid;
    border-bottom: 1px solid;
    @include theme-border-color('border-placeholder-light');
    padding: $base*2 0;
    margin-bottom: $base*6;

    text-align: center;
    font-size: 14px; 

    &__text {
        @include theme-text-color('text-semi');
    }

    &__button {
        margin-left: $base;
    }
    
    &__button-link {
        @include utility-link();
        text-decoration: underline;
    }
}

.message5 {
    //display: flex;
    //align-items: center;
    padding: 20px 30px;

    border-radius: 6px;
    background-color: rgba(43, 215, 224, 0.1); // #2BD7E0 10%

    &__body {
        margin-bottom: 15px;
        font-family: $ff-main;
    }

    &__title {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.01em;
    }
    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.01em;
    }
    &__accent {
        color: #25C7CF;
    }

    &__button {
        margin-left: auto;
    }
}

.message6 {
    $root: &;

    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    overflow: hidden;

    &--info {
        @include light_dark(border-color, #7de7ec, rgb(19, 125, 130));

        #{$root}__header {
            @include light_dark(background-color, #d3f7f9, #106469);
        }
    }

    &--error {
        @include light_dark(border-color, #fdd3c4, #7e4936);

        #{$root}__header {
            @include light_dark(color, #f8865c, #a33107);
            @include light_dark(background-color, #fef3ee, $red);
        }
    }

    &__header {
        padding: 10px 20px;
        font-weight: 500;
    }

    &__body {
        padding: 20px 20px;
        line-height: 1.4;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}
