/* blocks */
.campaign-name {

    &__title {
        @include h1-title();
        @include adaptive-text-margin($base, $base*2);
    }

    &__subtitle {
        @include h5-title();
    }
}

.author-block {
    
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    &__avatar {
        flex: 0 0 auto;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        @include upto(medium-screens) {
            width: 40px;
            height: 40px;
            margin-right: $base*2;
        }

        @include for(medium-screens) {
            width: 60px;
            height: 60px;
            margin-right: $base*3;
        }
    }

    &__content {
        position: relative;
        

        @include upto(medium-screens) {
            width: calc(100% - 40px - 12px);
        }

        @include for(medium-screens) {
            width: calc(100% - 60px - 18px);
        }
    }

    &__name {
        @include title-with-next($height: 20px, $icon: 11px);
        font-size: 15px;
        font-weight: 600;

        span {
            display: inline-block;
            padding-bottom: 2px;
        }
    }

    &__label {
        font-size: 11px;
    }
}

.author-details {
    position: absolute;
    margin-top: $base;

    padding: $base*2;
    width: calc(100% - 52px);
    left: 52px;
    
    border: 1px solid;
    @include theme-border-color('border-donation');
    @include theme-background-color('background-body');
    border-radius: 7px;
    z-index: 1000;

    font-size: 13px;

    @include for(large-screens) {
        left: 60px + 18px + 36px;
        width: 420px;
        padding: $base*3;
    }

    &__more {
        margin-top: $base;

        a {
            @include primary-link();
        }
    }

    &__close {
        width: 20px;
        height: 24px;

        position: absolute;
        top: 0;
        right: 0;

        .icon-close {
            width: 10px;
            height: 10px;
            fill: $color-brown;
            margin-top: 8px;
        }
    }
}


.campaign-about {

    &__summary { 
        @include dont-break-out();
        
        @include upto(large-screens) {
            hyphens: auto;
            padding-bottom: $base*4;
        }

        @include for(large-screens) {
            padding-left: $base*6;
        }
    }
}

// about
.about-section {
    border-top: 1px dashed $color-primary;

    @include for(large-screens) {
        padding-top: $base*6;   
        margin-top: $base*6;

        &.campaign-about__cause   {
            margin-top: $base*3;
        }
    }

    &__title {
        
        @include upto(large-screens) {
            @include h3-title();

            @include title-with-next($height: 18px, $icon: 11px);
            padding: $base*4 0;
        }

        @include for(large-screens) {
            @include h2-title();
            margin-bottom: $base*4;
            padding-left: 36px;

            .icon-next {
                display: none;
            }
        }
    }

    &__content {
        @include dont-break-out();

        @include upto(medium-screens) {
            hyphens: auto;
            padding-bottom: $base*4;
        }

        @include between(medium-screens, large-screens) {
            padding-bottom: $base*4;
        }

        @include for(large-screens) {
            padding-left: 36px;
        }
    }
}

.metadata-block {

    &__row + &__row {
        border-top: 1px dashed transparentize($color-primary, 0.5);
        margin-top: $base*2;
        padding-top: $base*2;
    }

    &__label {
        font-weight: 600;
        margin-bottom: $base*0.5;
    }

    @include for(large-screens) {
        &__row + &__row {
            margin-top: $base*3;
            padding-top: $base*3;
        }
    }
}

.campaign-statement {

    a {
        @include primary-link();
    }

    @include upto(large-screens) {
        padding-bottom: $base*4;
    }
    
    @include for(large-screens) {
        padding-bottom: $base*6;
        padding-left: 36px;
    }
}

.campaign-archive {
    border-top: 1px dashed $color-primary;

    &__title {
        @include h3-title();
        @include title-with-next($height: 18px, $icon: 11px);
        padding: $base*4 0;
        cursor: pointer;
    }

    @include for(large-screens) {
        &__title {
            padding: $base*6 0 $base*4 $base*6;
        }
    }
}
