// Custom campaign related styles
.cc-content {
    p {
        margin-bottom: 1.5em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .lead {
        font-size: 1.2em;
    }

    .image-block {
        display: grid;
        gap: 25px;
        padding: 25px;
        border-radius: 25px;
        margin: 1.75em 0;

        @include theme-background-color('background-donation');

        @include upto(medium-screens) {

        }

        @include for(medium-screens) {
            grid-template-columns: 45% 1fr;
            align-items: center;
        }
    }

    figure.full {
        margin-bottom: 1.5em;

        &:last-child {
            margin-bottom: 0;
        }

        img {
            width: 100%;
            height: auto;
        }

        figcaption {
            margin-top: $base;
            font-size: 13px;
            line-height: 1.25;
        }
    }

    .columns img {
        border-radius: 2px;
    }
}

// Thank you message
.thankyou-notice {
    margin-top: 40px;
    font-size: 13px;
    line-height: 1.35;
    padding: 0 6px;
}

// Form page
.cc-alert {
    font-size: 16px;
    padding: 24px 36px;
    border: 2px solid;
    border-radius: 25px;

    @include for(medium-screens) {
        min-width: 600px;
    }

    &.cc-success {
        border-color: $green;
    }

    &.cc-info {
        border-color: $blue;
    }
}

.content-columns__narrow {
    .cc-alert {
        margin-top: 24px;
    }
}

.cc-form-intro {
    margin-top: 24px;
    margin-bottom: 36px;
}

.cc-adaptive-image {
    margin: 1.5em 0;

    &:last-child {
        margin-bottom: 0;
    }

    &__desktop {
        @include upto(medium-screens) {
            display: none;
        }
    }

    &__mobile-top {
        @include for(medium-screens) {
            display: none;
        }
    }

    &__mobile-bottom {
        @include for(medium-screens) {
            display: none; 
        }
    }
}