/** Paging */
.pagination {
	display: flex;
	flex-flow: row;
    flex-wrap: wrap;
	margin-left: -3px;
}

.page-link {
	display: inline-block;
	padding: 5px 14px;
	margin: 0 3px;
	background: transparent;
	border-radius: 2px;
	text-decoration: none;
    @include theme-text-color('text-body');

    &:hover, &:focus, &:active {
    	background: transparentize($color-primary, 0.8);
    }

    .active &, // old, non-flexible
    &--active {
    	background: transparentize($color-primary, 0.2);
    }

    &--separator {
        cursor: default;

        &:hover {
            background: transparent;
        }
    }
}


// Used in: "Authors catalog" listing
.paging3 {
    display: flex;
    align-items: center;

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;

        border-radius: 80px;
        font: 500 16px/1 $ff-main;

        &--active {
            color: $white !important;
            background-color: $green;
            cursor: default;
        }
    }

    &__link {
        @include theme-prop($prop--color, $key--text-body);

        &:hover {
            color: $color-link;
            transition: 400ms;
        }
    }

    &__separator {
        cursor: default;
        user-select: none;
    }

    &__first, &__last {
        width: 24px;
        height: 24px;

        svg {
            width: 8px;
            height: 8px;
            fill: $color-link;
        }
    }

    &__first {
        svg {
            transform: rotate(180deg);
        }
    }
}
