// Palettes
@mixin theme-background-color($color) {
    @media (prefers-color-scheme: dark) {
        background-color: dark_color($color);
    }
    @media (prefers-color-scheme: light) {
        background-color: light_color($color);
    }
}

@mixin theme-text-color($color) {
    @media (prefers-color-scheme: dark) {
        color: dark_color($color);
    }
    @media (prefers-color-scheme: light) {
        color: light_color($color);
    }
}

@mixin theme-border-color($color) {
    @media (prefers-color-scheme: dark) {
        border-color: dark_color($color);
    }
    @media (prefers-color-scheme: light) {
        border-color: light_color($color);
    }
}

@mixin theme-fill-color($color) {
    @media (prefers-color-scheme: dark) {
        fill: dark_color($color);
    }
    @media (prefers-color-scheme: light) {
       fill: light_color($color);
    }
}

@mixin theme-stroke-color($color) {
    @media (prefers-color-scheme: dark) {
        stroke: dark_color($color);
    }
    @media (prefers-color-scheme: light) {
       stroke: light_color($color);
    }
}
