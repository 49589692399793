/* logo in header **/

.logo-full {
    display: block;
    
    img {
        width: 100%;
        height: 100%;
    }

    &--default {
        @include upto(large-screens) {
            width: 165px;
            height: 40px;
        }

        @include for(large-screens) {
            width: 182px;
            height: 44px;
        }
    }

    &--new-year {
        @include upto(large-screens) {
            width: 165px;
            height: 49px;
        }

        @include for(large-screens) {
            width: 182px;
            height: 54px;
        }
    }

    .white {
        display: none;
    }

    .black {
        display: block; 
    }

    @include for-dark-theme() {
        .white {
            display: block;
        }

        .black {
            display: none; 
        }
    }
}
