/** Reusable mixins and function **/

$escaped-characters: (
  ("<","%3c"),
  (">","%3e"),
  ("#","%23"),
  ("(","%28"),
  (")","%29"),
);

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    } 

    @return $string;
}

// See https://codepen.io/kevinweber/pen/dXWoRw
@function escape-svg($string) {
    @if str-index($string, "data:image/svg+xml") {
        @each $char, $encoded in $escaped-characters {
            // Do not escape the url brackets
            @if str-index($string, "url(") == 1 {
                $string: url("#{str-replace(str-slice($string, 6, -3), $char, $encoded)}");
            } @else {
                $string: str-replace($string, $char, $encoded);
            }
        }
    }

    @return $string;
}


@mixin link-color( $color ) {
    color: $color;
    &:hover {
        // color: darken($color, 15%);
        color: saturate($color, 25%);
        transition: color .35s;
    }
}

@mixin utility-link() {
    color: $color-link;
    &:hover {
        color: mix($color-primary, $color-link, $weight: 50%);
    }
}

@mixin intext-auto-link() {
    color: $color-brown;
    border-bottom: 1px solid transparentize($color-brown, 0.4);
    transition: .35s;

    &:hover {
        color: darken($color-brown, 10%);
        border-color: darken($color-brown, 10%);
    }
}

@mixin primary-link() {
    color: $color-primary;
    text-decoration: underline;

    &:hover {
        color: mix($color-link, $color-primary, $weight: 50%);
    }
}

// used to use in .action-block
@mixin side-link() {
    color: $brown;
    font-weight: 500;
    text-decoration: underline;
    transition: color .35s; 

    &:hover {
        color: darken($brown, 10%);
    }
}


@mixin tooltip-style() {
    background: $dark;
    color: $color-white;
    font-size: 11px;
    line-height: 12px;
}

@mixin title-with-next($height: 13px, $icon: 10px) {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    coursor: pointer;

    span {
        display: inline-block;
        line-height: $height;
        padding-bottom: 1px;
    }

    .svg-icon {
        fill: $color-primary;
        width: $icon;
        height: $icon;
        margin-left: $icon*0.75;
    }

    &.is-open .svg-icon {
        transform: rotate(90deg);
    }
}


@mixin site-content-gap-no-feature {
    @include upto(large-screens) {
        padding-top: $base*2;
    }

    @include for(large-screens) {
        padding-top: $base*4.5;
    }
}

@mixin dont-break-out {
    // based on https://css-tricks.com/better-line-breaks-for-long-urls/
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    hyphens: auto;
}


/* account text content slyting */

@mixin main-text-content() {

    @include body-text();

    p + p {
        margin-top: $base*3;
    }

    li a,
    p a {
        @include link-color( $brown );
    }
}

@mixin side-text-content() {

    @include side-text();

    p + p {
        margin-top: $base*2;
    }

    a {
        @include side-link();
    }
}


// "emla" slider doesn't understand "width" prop for some reason :(
@mixin embla_width($val) {
    min-width: $val;
    max-width: $val;
}



// Buttons
// also see includes/_buttons.scss

@mixin button-gradient-with-hover() {
    color: $color-white;
    background-size: 200% auto;
    background-image: linear-gradient(to right, $green 0%, $blue 48%, $green 100%);
    transition: 0.5s;

    &:hover {
        background-position: right center;
    }
}
