
.campaign-support {
    border: 2px solid;
    @include theme-border-color('border-donation');

    @include upto(medium-screens) {
        border-radius: 24px;
    }

    @include between(medium-screens, large-screens) {
        border-radius: 12px;
    }

    @include for(large-screens) {
        position: sticky;
        top: 125px;
        border-radius: 24px;
    }

    &__title {
        @include h4-title();
        text-align: center;
        @include theme-text-color('text-body');
        
        @include upto(medium-screens) {
            padding: $base*3 $base*4;
        }

        @include between(medium-screens, large-screens) {
            padding: $base*5;
        }

        @include for(large-screens) {
            padding: $base*5;
        }
    }

    &__data {
        @include upto(medium-screens) {
            padding: 0 $side_padding_mobile $base*3;
        }

        @include between(medium-screens, large-screens) {
             padding: 0 $side_padding_mobile $base*3;
        }

        @include for(large-screens) {
            padding: 0 $side_padding_desktop $base*3;
        }
    }   
}


.campaign-action {

    &__open-trigger {
        @include theme-background-color('background-donation');

        @include upto(large-screens) {
            padding: $base*4;
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;
            padding: $side_padding_mobile;
        }

        @include for(large-screens) {
            padding: $base*5;
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;
            padding: $side_padding_desktop;
        }

    }

    &__close-trigger {
        @include theme-background-color('background-donation');
        cursor: pointer;

        @include upto(medium-screens) {
            padding: $base*2 $side_padding_mobile $base*4;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        @include for(large-screens) {
            padding: $base*2 $side_padding_desktop $base*3;
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;
        }
    }

    .donation-form__content {
        @include theme-background-color('background-donation');

        @include upto(medium-screens) {
            padding: $base*4 $base*4 0;
        }

        @include for(large-screens) {
            padding: $base*5 $base*5 0;
        }
    }

    &__status-wrap {
        @include theme-background-color('background-donation');

        @include upto(medium-screens) {
            padding: $base*4;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: $side_padding_mobile;
        }

        @include for(large-screens) {
            padding: $base*5;
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;
            padding: $side_padding_desktop;
        }
    }

    @mixin status-text() {
        font-family: $text;
        font-size: 1.1em;
        line-height: 24px;
        text-align: center;
        text-transform: lowercase;
    }

    &__closed-status {
        display: inline-block;
        @include status-text();

        border-radius: 25px;
        transition: all .35s;
        width: 100%;
        padding: 14px 24px;

        @include for-light-theme() {
            color: transparentize($dark, 0.1);
            border: 1px solid #E2DAD2;
            background: linear-gradient(to left, #E3DBD3 20%, #F6F3EE);
        }

        @include for-dark-theme() {
            color: $white;
            border: 1px solid transparentize(#EFECE4, 0.6);
            background: linear-gradient(to left, #E3DBD3 20%, #4F5251);
        }
    }

    &__finished-status {
        display: inline-block;
        @include status-text();
        font-weight: 500;

        border-radius: 25px;
        transition: all .35s;
        width: 100%;
        padding: 14px 24px;

        color: $color-primary;
        border: 1px solid $color-primary;
        @include theme-background-color('background-body');
    }
}

.campaign-data {
    &__top {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: $base*2;
    }

    &__bottom {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-top: $base*2;
    }

    &__collected {
        color: $color-primary;
        line-height: 27px;

        @include upto(medium-screens) {
            font-size: 24px;
        }

        @include for(medium-screens) {
            font-size: 27px;
        }
    }

    &__endless-collected {
        color: $color-primary;
        font-size: 21px;
        line-height: 27px;
    }

    &__endless-type {
        font-size: 13px;
        text-transform: lowercase;
    }

    &__days {
        text-align: right;
        color: $brown;
        font-size: 11px;
        line-height: 27px;
    }

    &__percent {
        font-size: 13px;
    }

    &__supports {
        font-size: 13px;
        text-align: right;
    }
}
