.header-menu-toggle  {
    &__trigger {
        cursor: pointer;
    }

    &__list {
        position: absolute;
        top: 0;
        right: -8px;

        width: 200px;
        padding: 60px 12px 12px;
        z-index: 2000;

        @include theme-background-color('background-body');
        border: 1px solid;
        @include theme-border-color('border-donation');
        border-radius: 7px;
    }

    &__close {
        display: block;
        width: 36px;
        height: 36px;

        position: absolute;
        top: 0;
        right: 0;

        .icon-close {
            width: 16px;
            height: 16px; 
            fill: $color-brown;
            margin: 13px 0px 0 7px;
        }
    }

    /* The marker about unread notifications */
    &__marker {
        width: 9px;
        height: 9px;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 50%;
        background: $color-primary;

        @include for(medium-screens) {
            display: none;
        }
    }
}

$author_mini_base: 30px;

.author-mini {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    border-radius: 24px;

    @include for(large-screens) {
        padding: 2px;
        border: 1px solid;
        @include theme-border-color('border-donation');
    }
 
    &__pic {
        width: $author_mini_base;
        height: $author_mini_base;
        border-radius: 50%;

        @include upto(large-screens) {
            width: $author_mini_base + 2px;
            height: $author_mini_base + 2px;
        }
    }

    &__name {
        font-size: 11px;
        font-weight: 500;
        line-height: $author_mini_base;
        height: $author_mini_base;
        padding: 0 10px;

        @include upto(medium-screens) {
            display: none;
        }
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &__placeholder {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: relative;
        @include theme-background-color('background-option');

        .svg-icon {
            width: 20px;
            height: 20px;
            @include theme-fill-color('text-semi');
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}


%_menu-down__item--separated {
    margin-top: 8px;
    padding-top: 8px !important; /* dirty */
    border-top: 1px solid $color-border-regular;
}

.menu-down {
    &__item {
        padding: 5px 0;
        font-size: 14px;

        &--separated {
            @extend %_menu-down__item--separated;
        }
    }

    /* The marker about unread notifications */
    &__item-marker {
        display: inline-block;
        width: 9px;
        height: 9px;
        position: relative;
        right: 0;
        top: -3px;

        border-radius: 50%;
        background: $color-primary;
    }

    &__link {
        @include sitenav-link();
        @include theme-text-color('text-body');
        transition: color .35s;
        &:hover {
            color: $color-primary;
        }
    }

    &__avatar {
        position: absolute;
        top: 12px;
        left: 12px;
        width: $author_mini_base;
        height: $author_mini_base;

        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


.n10n-bell {
    z-index: 2;

    &__link {
        display: block;
        position: relative;
        z-index: 3;
    }

    &__icon {
        display: block;
        width: 24px;
        height: 24px;
        position: relative;
        top: 2px;

        .svg-icon {
            width: 100%;
            height: 100%;

            @include for-dark-theme() {
                $base: dark_color('border-donation');
                fill: transparentize($base, 0.2);
            }
            @include for-light-theme() {
                fill: light_color( 'border-donation' );
            }
        }
    }

    &__counter {
        display: inline-block;
        position: absolute;
        z-index: 4;
        border-radius: 50%;
        background: $color-primary;
        min-width: 18px;
        aspect-ratio: 1/1;
        padding: 4px 3px 3px;
        right: -8px;
        top: -1px;
        border-radius: 50%;

        color: $color-white;
        font-weight: 600;
        font-size: 11px;
        line-height: 10px;
        text-align: center;
    }

    @include upto(medium-screens) {
        height: 34px;

        &__icon {
            top: 7px;
        }

        &__counter {
            top: 2px;
        }
    }

    @include for(medium-screens) {
        height: 36px;

        &__icon {
            top: 7px;
        }

        &__counter {
            top: 2px;
        }
    }
}

.payout-stat {

    &__amount {
        
        a {
            display: block;
        }

        .label {
            @include theme-text-color('text-body');
            display: block;
            font-size: 9px;
            line-height: 13px;
            text-transform: lowercase;
        }

        .amount {
            display: block;
            font-size: 12px;
            font-weight: 600;
            color: $color-primary;
        }

        @include upto(large-screens) {
            display: none;
        }
    }
    
    &.active {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
    }

    &__button {
        .mark {
            display: inline-block;
            text-align: center;
            font-weight: 500;
            border-radius: 50%;
            @include theme-text-color('text-body');
            @include theme-background-color('background-option');
            transition: .35s;

            @include for(large-screens) {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }

            @include upto(large-screens) {
                width: 33px;
                height: 33px;
                line-height: 33px;
            }
        }

        a.mark:hover {
            background: $color-primary;
        }
    }

    &.active &__button {
        .mark {
            background: $color-primary;
            color: $color-white;
        }
    }


    &.inactive &__button {
        opacity: 0.65;
    }
} 
