

.form-group {
    margin: 0 0 $base*4;
    position: relative; 

    label {
        display: inline-block;
        @include form-title();
        margin-bottom: $base;

        &.has-link {
            a {
                @include utility-link();
                font-weight: 400;
                font-size: 80%;
            }

            @include for(medium-screens) {
                width: 100%;
                position: relative;

                a {
                    position: absolute;
                    bottom: -35px;
                    right: 10px;
                }
            }
        }
    }

    &.invalid label {
        @include theme-text-color('error');
    }

    &.valid label {
        @include theme-text-color('success');
    }

    input {
        width: 100%;
        padding: 8px 12px;
        @include form-field-border();
        @include form-field-input-text();
        @include theme-background-color('background-field-base');
        outline: none;

        &:focus:not([readonly]) {
            @include theme-background-color('background-field-focus');
            &::placeholder {
                opacity: 0.3;
            }
        }

        &[disabled] {
            color: $color-text-light;
        }

        &[readonly] {
            @include theme-text-color('text-placeholder');
        }
    }

    &__password-icon {
        position: absolute;
        right: 10px;
        top: 42px;
        transition: opacity .35s;

        &:hover {
            cursor: pointer;
        }

        .icon-eye,
        .icon-eye-cross {
            width: 24px;
            height: 17px;
            opacity: 0.5;
            transition: .35s;

            @include for-light-theme() {
                fill: light_color('text-body');
            }

            @include for-dark-theme() {
                fill: dark_color('text-light');
            }
        }

        &:hover .svg-icon {
            opacity: 0.9;
        }
    }

    &.invalid input {
        @include theme-border-color('error');
    }

    &.valid input {
        @include theme-border-color('success');
    }

    textarea {
        resize: vertical;
        width: 100%;
        padding: 8px 12px;
        @include form-field-border();
        @include form-field-input-text();
        @include theme-background-color('background-field-base');


        &.size-small {
            height: 70px;
        }

        &.size-medium {
            height: 120px;
        }

        &.size-large {
            @include upto(large-screens) {
                height: 420px;
            }

            @include for(large-screens) {
                height: 300px;
            }
        }

        &:focus {
            @include theme-background-color('background-field-focus');
            &::placeholder {
                opacity: 0.3;
            }
        }
    }

    &.invalid textarea {
        @include theme-border-color('error');
    }

    &.valid textarea {
        @include theme-border-color('success');
    }

    &__prefixed {
        input {
        }

        @include upto(large-screens) {
        }

        @include for(large-screens) {
            display: flex;
            flex-flow: nowrap;
            justify-content: space-between;

            input {
                width: auto;
                flex: 1 1 auto;
            }
        }
    }

    &__prefix {
        flex: 0 0 auto;
        margin-right: $base*2;
        @include form-prefix-text();
    }

    &__select {
    }

    &__helptext {
        @include form-help-text();
        margin-top: $base;
        padding-left: 2px;
    }

    &__error {
        @include form-error-text();
        padding: 3px 0 0 3px;
    }

    &__help {
        font-size: 11px;
        margin-top: $base;
        @include theme-text-color('text-semi');
    }
}


.form-solve {
    margin: 0 0 $base*4;
    position: relative;

    label {
        @include form-title();
        margin-right: $base*2;
    }

    input {
        padding: 8px;
        width: 60px;
        @include form-field-border();
        @include form-field-input-text();
        @include theme-background-color('background-field-base');
        outline: none;

        &:focus {
            @include theme-background-color('background-field-focus');
        }
    }

    &.invalid input {
        @include theme-border-color('error');
    }

    &.valid input {
        @include theme-border-color('success');
    }

    &.invalid label {
        @include theme-text-color('error');
    }

    &.valid label {
        @include theme-text-color('success');
    }
}


.demo-input {
    width: 100%;
    min-height: 38px;
    border: 1px solid;
    @include theme-border-color('background-side-block');
    padding: $base 12px;

    @include theme-background-color('background-field-base');
    border-radius: 6px;

    span {
        cursor: default;

        @include for(large-screens) {
            padding-right: 120px;
        }
    }
}
