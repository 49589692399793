/* thank you */
.author-thankyou {
    

    &__title {
        @include h1-title();
    }

    &__header {
        margin-top: 27px;
        margin-bottom: $base*6;
    }

    &__more {
        max-width: 700px;
    }

    &__message {
        max-width: 680px;
        margin-bottom: 120px;
    }
}

.more-projects {

    &__title {
        @include h3-title();
        margin-bottom: $base*4;

    }
}

.thankyou-quote {

    @include theme-background-color('background-donation'); 
    padding: $base*6 $base*5;
    border-radius: 3px;
    position: relative;

    &:before {
        content: '“';
        display: inline-block;
        color: $color-white; 
        @include theme-text-color('scale-base'); 
        font-size: 100px;
        line-height: 1;
        position: absolute;
        top: -10px;
        left: 27px;
    }

    &__text {
        font-size: 21px;
        line-height: 1.5;
        margin-bottom: $base*4;

        a {
            color: $color-brown;
            text-decoration: underline;
            font-weight: 500;
        }
    }

    &__cite {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        color: inherit;
    }

    &__name {
        font-weight: 600;
        margin-left: $base*2;
    }

    &__avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 3px solid $color-white;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    &__name {

    }
}
