@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat-Regular'),
         url('../fonts/Montserrat-Regular.woff2') format('woff2'),
         url('../fonts/Montserrat-Regular.woff') format('woff'),
         url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility; 
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat-Medium'),
         url('../fonts/Montserrat-Medium.woff2') format('woff2'),
         url('../fonts/Montserrat-Medium.woff') format('woff'),
         url('../fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    text-rendering: optimizeLegibility; 
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat-SemiBold'),
         url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
         url('../fonts/Montserrat-SemiBold.woff') format('woff'),
         url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    text-rendering: optimizeLegibility; 
}

// Downloaded from: https://fonts.google.com/specimen/Montserrat
// Converted from TTF to other formats in: https://transfonter.org/
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat-Bold'),
         url('../fonts/Montserrat-Bold.woff2') format('woff2'),
         url('../fonts/Montserrat-Bold.woff') format('woff'),
         url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    text-rendering: optimizeLegibility;
}
