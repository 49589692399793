/** Sharing **/
.sharing-buttons {
	list-style-type: none;
	margin: 0;
	padding: 0;

	li  { 
		width: 46px;
		height: 36px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
	}

	a {
		width: 36px;
		height: 36px;
		background: $color-white;
		border-radius: 50%;
		border: 1px solid $color-grey;

		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		text-decoration: none !important;
		cursor: pointer;
		transition: all 0.35s;

		&:hover {
			background: $color-white;
			border-color: $color-grey;
			box-shadow: 1px 1px 5px transparentize( $color-grey, 0.25);
			transform: scale(1.05);
		}
	}
	
	.svg-icon {
		display: block;
		width: 17px;
		height: 17px;
		margin: 0 auto;

		@include upto(medium-screens) {
			width: 15px;
			height: 15px;
		}
	}

	.icon-link {
		width: 16px;
		height: 16px; 

		@include upto(medium-screens) {
			width: 14px;
			height: 14px;
		}
	}
}


@include upto(large-screens) {
	.sharing-buttons {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
	}
}

@include for(large-screens) {
	.sharing {
		position: absolute;
		width: 50px;
		right: 0;

		li {
			height: 44px;
		}
	}
}


// Sharing block on author/campaign pages
// copied from the journal
@mixin sharing-text {
    font-size: 14px;
    line-height: 1;
    text-transform: lowercase;
    font-weight: 400;
    letter-spacing: -0.025em;
}

@mixin sharing-text-link-color() {
    @include theme-text-color('text-body');
    transition: .35s;

    &:hover {
        color: $color-primary;
    }
}

.sharing2-wrapper {
    &--author-desktop {
        @include upto(large-screens) {
            display: none;
        }

        @include for(large-screens) {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }

    &--author-mobile {
        @include upto(large-screens) {
            display: flex;
            justify-content: center;

            .sharing2 {
                width: 36px;
                height: 36px;
                gap: 0;

                &__trigger {
                    width: 36px;
                    height: 36px;
                    gap: 0;
                    border-radius: 50%;
                }

                &__buttons {
                    right: -53px;
                }

                .label {
                    display: none;
                }
            }
        }

        @include for(large-screens) {
            display: none;
        }
    }

    &--campaign-desktop {
        @include upto(medium-screens) {
            display: none;
        }

        @include for(medium-screens) {
            display: flex;
            justify-content: center;
            margin-left: auto;
        }
    }

    &--campaign-mobile {
        @include upto(medium-screens) {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }

        @include for(medium-screens) {
            display: none;
        }
    }
}

.sharing2 {
    width: 170px;
    position: relative;
    user-select: none;

    &__trigger {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border-radius: 17px;

        width: 100%;
        height: 34px;
        gap: $base*2;
        cursor: pointer;
        @include theme-background-color('background-option'); // #EEE8E0
        transition: .35s;

        &:hover {
            @include theme-background-color('background-side-block');
        }

        .label {
            @include sharing-text();
            @include theme-text-color('text-body');
        }

        .icon {
            .svg-icon {
                width: 13px;
                height: 12px;
                @include theme-fill-color('text-body');
            }
        }
    }

    &__buttons {
        margin-top: 5px;
        position: absolute;
        padding: $base*2 $base*3;
        border-radius: 15px;

        border: 1px solid;
        @include theme-border-color('border-neutral');
        @include theme-background-color('background-body');
        z-index: 100;

        @include for-light-theme() {
            box-shadow: 0 5px 10px rgba(200,200,200, 0.3);
        }

        @include for-dark-theme() {
            box-shadow: 0 5px 10px rgba(10,10,10, 0.5);
        }
    }

    &.is-open &__trigger {
        @include theme-background-color('background-side-block');
    }

    &.is-open &__buttons {
        display: block;
        opacity: 1;
    }

    &__item {
        a {
            @include sharing-text();
            @include sharing-text-link-color();
        }
    }
}

.sharing3-wrapper {
    &--author-desktop {
        @include upto(large-screens) {
            display: none;
        }

        @include for(large-screens) {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }

    &--author-mobile {
        @include mq_upto($bp_large) {
            display: flex;
            justify-content: center;

            .sharing3 {
                width: 36px;
                height: 36px;
                gap: 0;

                &__trigger {
                    width: 36px;
                    height: 36px;
                    gap: 0;
                    border-radius: 50%;
                }

                &__dropdown {
                    @include mq_upto($bp_355) {
                        right: -67px;
                    }

                    @include mq_between($bp_355, $bp_small) {
                        right: -85px;
                    }

                    @include mq_for($bp_small) {
                        right: -227px;
                    }
                }

                .label {
                    display: none;
                }
            }
        }

        @include mq_for($bp_large) {
            display: none;
        }
    }

    &--campaign-desktop {
        @include mq_upto($bp_medium) {
            display: none;
        }

        @include mq_for($bp_medium) {
            display: flex;
            justify-content: center;
            margin-left: auto;
        }

        .sharing3 {
            &__dropdown {
                right: 0;
            }
        }
    }

    &--campaign-mobile {
        @include upto(medium-screens) {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }

        @include for(medium-screens) {
            display: none;
        }

        .sharing3 {
            &__dropdown {
                @include mq_upto($bp_355) {
                    right: -58px;
                }

                @include mq_between($bp_355, $bp_small) {
                    right: -66px;
                }
                @include mq_for($bp_small) {
                    right: -140px;
                }
            }
        }
    }
}

.sharing3 {
    width: 170px;
    position: relative;
    user-select: none;

    &__trigger {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border-radius: 17px;

        width: 100%;
        height: 34px;
        gap: $base*2;
        cursor: pointer;
        @include theme-background-color('background-option'); // #EEE8E0
        transition: .35s;

        &:hover {
            @include theme-background-color('background-side-block');
        }

        .label {
            @include sharing-text();
            @include theme-text-color('text-body');
        }

        .icon {
            .svg-icon {
                width: 13px;
                height: 12px;
                @include theme-fill-color('text-body');
            }
        }
    }

    &.is-open &__trigger {
        @include theme-background-color('background-side-block');
    }

    &__dropdown {
        margin-top: 10px;
        border: 1px solid;
        @include theme-border-color('border-neutral');
        @include theme-background-color('background-body');
        padding: 18px 18px 21px;
        position: absolute;
        z-index: 100;

        border-radius: 15px;

        @include for-light-theme() {
            box-shadow: 0 5px 10px rgba(200,200,200, 0.3);
        }

        @include for-dark-theme() {
            box-shadow: 0 5px 10px rgba(10,10,10, 0.5);
        }

        @include mq_upto($bp_355) {
            width: 290px;
        }

        @include mq_between($bp_355, $bp_small) {
            width: 310px;
        }

        @include mq_for($bp_small) {
            width: 450px;
        }
    }

    &__dropdown-title {
        margin-bottom: 8px;

        font-size: 14px;
        line-height: 1;
        text-transform: lowercase;
        font-weight: 400;
        letter-spacing: -0.025em;
    }
}
