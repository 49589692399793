
.author-profile {

    .no-projects & {
        @include for(large-screens) {
            padding-top: $base*5;
        }
    }

    &__name {
        @include h1-title();
        @include adaptive-text-margin($base, $base*2);
        word-wrap: break-word;
    }

    &__brand {
        @include h5-title();

        @include upto(medium-screens) {
            margin-bottom: $base;
        }

        @include for(medium-screens) {
            margin-bottom: $base*3;
        }
    }

    &__about {

    }
}

.author-about {

    @include upto(medium-screens) {
        &__trigger {
            @include theme-text-color('primary');
            text-transform: lowercase;
            font-size: 13px;
            padding: $base*2 0;

            @include title-with-next();
        }

        &__tiser-close {
            display: none;
        }

        .the-content {
            padding: $base 0 0;
        }
    }

    @include for(medium-screens) {
        &__trigger {
        }
    }
}

@mixin tiser-link() {
    color: $color-brown;
    text-transform: lowercase;
    text-decoration: underline;
    coursor: pointer;
    transition: .35s;
    &:hover {
        color: darken($color-brown, 7%);
    }
    
}

.author-why {
    &__title {
        @include h2-title();
        margin-bottom: 21px;
    }

    &__tiser {
    }

    &__tiser-open {
        @include tiser-link();
    }

    &__tiser-close {
        @include tiser-link();
    }

    @include upto(medium-screens) {
        &__tiser-close {
        }

        .the-content {
            padding: $base 0 0;
        }
    }
}
