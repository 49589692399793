/** Footer **/
.footer-spacer {
    width: 100%;

    @include upto(large-screens) {
        height: 90px;
    }

    @include for(large-screens) {
        height: 110px;
    }
}

.site-footer { 
    @include theme-background-color('background-dark'); 
    padding: $base*8 0 $base*12;

    @include mq_upto($bp_medium) {
        padding-bottom: $base * 7;
    }

    .container {
        @include mq_between($bp_medium, $bp_large) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
        }

        @include mq_for($bp_large) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
    }

    // columns
    &__main {
        @include mq_between($bp_medium, $bp_large) {
            margin-left: $base*8;
        }

        @include mq_for($bp_large) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
    }

    &__aside {
        @include mq_between($bp_medium, $bp_large) {
            margin-left: auto;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            text-align: right;
            padding-top: 12px;
        }

        @include mq_for($bp_large) {
            margin-left: auto;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            text-align: right;
        }
    }

    // copyright
    &__copy {
        font-size: 11px;
        line-height: 1;
        text-transform: none;
        color: transparentize($white, 0.7);

        @include mq_upto($bp_medium) {
            margin-top: $base*12;
            padding-left: 6px;
        }
    }

    // telegram link
    &__enter {
        @include mq_upto($bp_medium) {
            margin-top: $base*8;
        }

        @include mq_for($bp_large) {
            padding-top: 6px;
        }
    }

    // logo
    &__branding {
        @include mq_between($bp_medium, $bp_large) {
            width: 36px;
            flex: 0 0 auto;
        }

        @include mq_for($bp_large) {
            width: 36px;
            flex: 0 0 auto;
        }
    }

    &__menu {
        @include mq_upto($bp_medium) {
            margin-top: $base*6;
        }

        @include mq_between($bp_medium, $bp_large) {
            margin-top: $base*6;

            &:first-child {
                margin-top: 6px;
            }
        }

        @include mq_for($bp_large) {
            margin-left: $base*12;
        }

        li {
            margin-bottom: 2px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__menu-title {
        color: transparentize($white, 0.35);
        font-size: 13px;
        line-height: 26px;

        @include mq_between($bp_medium, $bp_large) {
            padding: 12px 0 10px;
        }

        @include mq_for($bp_large) {
            padding: 12px 0 5px;
        }
    }

    &__menu-link {
        display: inline-block;
        font-size: 12px;
        line-height: 16px;
        color: transparentize($white, 0.65);
        transition: all .35s;

        &:hover {
            color: transparentize($white, 0.35);
        }
    }
}


$footer-blue: #0088cc;

.footer-social { 
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 10px;

    @include mq_upto($bp_medium) {
        justify-content: flex-start;
    }

    &__text-link {
    }

    &__link {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border: 1px solid $green; // transparentize( $color-white, 0.5);
        border-radius: 50%;
        transition: all .35s;
    }

    .svg-icon {
        display: inline-block;
        fill: $color-white;
        margin-left: -1px;
        transition: all .35s;
    }

    .icon-telegram {
        width: 13px;
        height: 11px;
    }

    .icon-vk {
        width: 14px;
        height: 9px;
    }

    &__link:hover {
        background: $color-white;
        border-color: $color-white;

        .icon-telegram {
            fill: $footer-blue;
        }

        .icon-vk {
            fill: #0077FF;
        }
    }
}


.logo-icon {
    display: block;
    
    img {
        width: 100%;
        height: 100%;
    }

    @include mq_upto($bp_medium) {
        width: 33px;
        height: 40px;
    }

    @include mq_for($bp_medium) {
        width: 35px;
        height: 43px;
    }
}
