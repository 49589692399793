
// used in: moderation and verification UI in Author Dashboard
.button2 {
    display: inline-block;
    padding: 10px 20px 11px;

    color: $color-white;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    font-family: $ff-main;
    text-align: center;
    text-transform: lowercase;
    text-decoration: none;

    @include light_dark(background-color, $dark, $black);
    border-radius: 24px;

    &--light {
        @include light_dark(color, $color-brown, #937646);
        @include light_dark(background-color, $color-white, transparentize($color-white, 0.15));
    }

    &--disabled {
        @include light_dark(color, $color-white, transparentize($color-white, 0.6));
        @include light_dark(background-color, transparentize($dark, 0.8), transparentize($dark, 0.6));
        cursor: default;
    }

    &--tiny {
        font-size: 12px;
        letter-spacing: -0.01em;
    }
}

// The button "Медиа об авторе" in authors catalog table
.button3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 50px;
    padding-left: 17px;
    padding-right: 10px;
    box-sizing: border-box;


    border-radius: 62px;
    background: linear-gradient(270deg, #11A4D7 0%, #2BD7E0 100%);

    &__icon {
        display: block;
        width: 23px;
        height: 26px;
        margin-right: 13px;

        svg {
            width: 23px;
            height: 26px;
            fill: #fff;
        }
    }

    &__text {
        color: $white;
        font: 600 12px/14.4px $ff-main;
    }
}

/* Used in: Footer - Socials */
.button4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    border: 1px solid $green; // transparentize( $color-white, 0.5);
    border-radius: 50px;
    padding: 0 24px;
    transition: all .35s;

    color: $color-white;
    font: 500 13px/1 $text; /* prev lh 20px or 153.846% */
    letter-spacing: 0.13px;
    text-align: center;

    &:hover {
        color: $color-blue-2;
        background-color: $color-white;
        border-color: $color-white;
    }
}

// Used in: the banner to Friendly author guide
.button5 {
    display: inline-block;
    border: 1px solid $green2;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-radius: 16px;

    @include theme-prop($prop--color, $key--text-body);
    font: 500 13px/1 $ff-main;
    letter-spacing: -0.01em;
    transition: .35s;

    &:hover {
        background-color: $green2;
        color: $color-white;
    }
}


// Used in: Home -> FAQ -> "extra" buttons
.button6 {
    display: inline-block;
    border: 1px solid $color-primary;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 999px;

    @include theme-prop($prop--color, $key--text-body);
    font: 500 14px/16.8px $ff-main;
    text-align: center;
    transition: .35s;

    &:hover {
        background-color: $green2;
        color: $color-white;

        .svg-icon {
            fill: $color-white;
        }
    }

    .svg-icon {
        max-width: 13px;
        max-height: 13px;
        margin-right: 8px;
        position: relative;
        top: 1px;
        transition: .35s;

        fill: $green2;
    }
}


// Used in: "Banners" slider on Main page
.button7 {
    display: inline-block;
    height: 54px;
    border: 2px solid $color-white;
    padding-top: 14px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 100px;
    box-sizing: border-box;

    color: $color-white;
    font: 500 16px/24px $ff-main;
    text-align: center;
    transition: .35s;

    span {
        transition: .35s;
    }

    &:hover {
        background-color: $color-white;

        span {
            color: $color-white;
            background-size: 200% auto;
            background-image: linear-gradient(to right, $blue 0%, $green 48%, $blue 100%);
            background-position: right center;
            -webkit-background-clip: text; // chrome
            background-clip: text; // firefox
            -webkit-text-fill-color: transparent; // chrome,firefox
        }
    }
}

// Used in: "Intro" block on Main page
.button8 {
    display: inline-block;
    border-radius: 500px;
    box-sizing: border-box;

    color: $color-white;
    text-align: center;
    @include button-gradient-with-hover();

    @include mq_upto($bp_355) {
        padding-left: 16px;
        padding-right: 16px;
    }

    @include mq_between($bp_355, $bp_388) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @include mq_between($bp_388, $bp_large) {
        padding-left: 22px;
        padding-right: 22px;
    }

    @include mq_upto($bp_large) {
        height: 56px;
        padding-top: 20px;
        position: relative;
        left: -2px;

        font: 600 15px/1 $ff-main;
    }

    @include mq_for($bp_large) {
        height: 60px;
        padding-top: 23px;
        padding-left: 45px;
        padding-right: 45px;
        font: 500 16px/1 $ff-main;
    }
}

// Used in: Email subscription banner on Main page
.button9 {
    display: inline-block;
    height: 54px;
    padding-top: 15.5px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 500px;
    box-sizing: border-box;

    color: $color-white;
    font: 500 16px/24px $ff-main;
    text-align: center;
    letter-spacing: normal;
    @include button-gradient-with-hover();
}