// Used in: "Authors catalog slider" on main page
.slider1 {
    position: relative;

    &__viewport {
        overflow: hidden;
        width: 100%;
        z-index: 10;
    }

    &__container {
        display: flex;
        user-select: none;
        width: 100%;
    }

    &__slide {
        position: relative;

        @include mq_upto($bp_medium) {
            $item-width: 233px; // "emla" slider doesn't understand "width" prop for some reason :(
            min-width: $item-width;
            max-width: $item-width;
            margin-right: 25px;
        }

        @include mq_between($bp_medium, $bp_large) {
            $item-width: 48%; // "emla" slider doesn't understand "width" prop for some reason :(
            min-width: $item-width;
            max-width: $item-width;

            margin-right: 4%;
        }

        @include mq_for($bp_large) {
            $item-width: 23.7%; // "emla" slider doesn't understand "width" prop for some reason :(
            min-width: $item-width;
            max-width: $item-width;

            margin-right: 1.73%;
        }
    }

    &__slide-inner {
        position: relative;
        overflow: hidden;
        min-height: 405px;
        height: 100%;
    }

    &__prev-next {
        @include mq_upto($bp_small) {
            display: none !important;
        }

        @include mq_between($bp_small, $bp_medium) {
            width: calc(100% + 90px) !important; // conflicts with .embla-authors
        }
    }
}



@mixin _author-card-2__small-desktop-paddings {
    @include mq_between($bp_large, $bp_1260) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

// Used in: "Authors catalog slider" on main page
.author-card-2 {
    height: 100%;
    position: relative;

    //padding: 27px 27px 17px 24px;
    padding-top: 27px;
    padding-bottom: 17px;

    border-radius: 16px;
    @include theme-prop($prop--background-color, $key--background-donation);

    @include mq_upto($bp_medium) {
        padding-left: 20px;
        padding-right: 20px;
    }

    &__link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        max-width: 233px;
        margin-left: auto;
        margin-right: auto;
    }

    &__badge {
        width: 79px;
        height: 27px;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        padding-left: 27px; // icon & text positioning
        padding-top: 4px; // icon & text positioning
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 15px;

        color: $color-white;
        font: 600 8px/8.4px $ff-main;
        letter-spacing: -0.01em;
        @include brand-gradient();

        svg {
            width: 14px;
            height: 16px;
            fill: $color-white;

            position: absolute;
            top: 5px;
            left: 7px;
        }
    }

    &__thumb-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 23px;

        overflow: hidden;

        @include mq_upto($bp_medium) {
        }

        @include mq_for($bp_medium) {
            width: 230px; // 5px higher for "margin-left: 5px" in &__thumb
            height: 225px;
        }
    }

    &__thumb {
        display: block;
        border-radius: 130px;

        @include mq_upto($bp_medium) {
            width: 185px;
            height: 185px;
        }

        @include mq_for($bp_medium) {
            width: 225px;
            height: 225px;
            margin-left: 5px;
        }
    }


    &__title {
        margin-bottom: 9px;

        @include theme-prop($prop--color, $key--text-body);
        font: 600 20px/1 $ff-main;
        letter-spacing: -0.01em;

        @include _author-card-2__small-desktop-paddings();
    }

    &__desc {
        @include theme-prop($prop--color, $key--text-body);
        margin-bottom: 19px;
        font: 14px/16.8px $ff-main;

        @include _author-card-2__small-desktop-paddings();
    }

    &__tags {
        margin-top: auto;

        color: #969492;
        letter-spacing: -0.01em;

        @include mq_upto($bp_medium) {
            font: 500 13px/1.3 $ff-main;
        }

        @include mq_for($bp_medium) {
            font: 500 11px/1.3 $ff-main;
        }

        @include _author-card-2__small-desktop-paddings();
    }
}


// "Banners" slider on Main page
.slider2 {
    $root: &;

    position: relative;

    &--single-slide {
        #{$root}__dots,
        #{$root}__nav-buttons{
            display: none;
        }
    }

    &__viewport {
        overflow: hidden;
        z-index: 10;

        @include mq_for($bp_large) {
            width: 100%;
        }

        @include mq_upto($bp_large) {
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__slides {
        display: flex;
        user-select: none;
        width: 100%;
    }

    &__slide {
        @include mq_for($bp_1600) {
            @include embla_width(100%);
        }

        @include mq_between($bp_large, $bp_1600) {
            @include embla_width(100%);
        }

        @include mq_between($bp_medium, $bp_large) {
            @include embla_width(94%);
            margin-right: 2%;
        }

        @include mq_upto($bp_medium) {
            @include embla_width(100%);
        }
    }

    &__nav-buttons {
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
    }

    &__nav-button {
        display: flex;
        justify-content: center;
        align-items: center;
        $button-size: 54px;
        width: $button-size;
        height: $button-size;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - ($button-size / 2));

        background-color: $color-white;

        @include mq_upto($bp_large) {
            display: none;
        }

        &--prev {
            transform: rotate(180deg);

            @include mq_upto($bp_1350) {
                left: -560px;
            }

            @include mq_between($bp_1350, $bp_1600) {
                left: -660px;
            }

            @include mq_for($bp_1600) {
                left: -740px;
            }

            .svg-icon {
                top: -1px;
                left: 1px;
            }
        }

        &--next {
            @include mq_upto($bp_1350) {
                right: -560px;
            }

            @include mq_between($bp_1350, $bp_1600) {
                right: -660px;
            }

            @include mq_for($bp_1600) {
                right: -740px;
            }

            .svg-icon {
                top: 1px;
                right: -2px;
            }
        }

        .svg-icon {
            width: 12px;
            height: 24px;
            position: relative;

            fill: $color-link;
        }
    }

    &__dots {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        @include mq_upto($bp_medium) {
            top: -30px;
        }

        @include mq_for($bp_medium) {
            top: -70px;
        }
    }

    &__dot {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border-radius: 50%;

        background-color: $color-white;
        opacity: 0.4;

        &:last-child {
            margin-right: 0;
        }

        &--selected {
            opacity: 1;
        }
    }
}
