.authors-catalog {
    $root: &;

    &__title {
        margin-bottom: 40px;
    }

    &__form {
        max-width: 400px;
        margin-bottom: 30px;
    }

    &__tags {
        margin-bottom: 40px;
    }

    &__list-wrapper {
        margin-bottom: 30px;
        position: relative;

        &--short {
            // hide 3rd (bottom) decor if the list is short (few items)
            & #{$root}__list-decor:nth-child(3) {
                display: none;
            }
        }
    }

    &__list-decor {
        position: absolute;
        width: 152px;
        height: 85px;
        z-index: 1;

        // TODO svg is applied via attr:style:background-color  for now,
        //      because webpack extracts the file to ./static/ which is ugly
        //      try to fix webpack conf
        //background-image: url('../svg/separate/decor-ribbon-1.svg');

        @include mq_upto($bp_medium) {
            display: none;
        }
    }

    &__list-decor-1 {
        top: -38px;
        right: -67px;
    }

    &__list-decor-2 {
        top: calc(50% - 40px);
        left: -80px;
        transform: rotate(90deg);
    }

    &__list-decor-3 {
        bottom: -22px;
        right: -66px;
    }

    &__list {
        position: relative;
        z-index: 5;
    }

    &__pagination {
        display: flex;
        justify-content: center;
    }
}
