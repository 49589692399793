/* content blocks */
.the-content {
    @include dont-break-out();

    h2 {
        margin-bottom: 1em;
    }

    h3 {
        font-size: 1.2em;
        margin-bottom: 0.5em;
    }

    ul {
        margin-bottom: 1.2em;
        
        li {
            position: relative;
            padding-left: 1.78em;
            a {
                @include utility-link();
            }

            &:before {
                content: "–";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0.025em;
            }
        }
    }

    a:not([class]) {
        @include intext-auto-link();
    }

    p {
        margin-bottom: 1.2em;
    }

    & > p:last-child {
        margin-bottom: 0;
    }
    
    @include upto(large-screens) {
        hyphens: auto;
    }

    @include for(large-screens) {

    }
}
