/* Typography rules */ 
$font-size-base:    16px;
$font-size-mobile:  14px; 
$font-size-form:    14px; 
$font-size-small:   12px;

@mixin adaptive-text-margin($mobile, $desktop) {
    @include upto(medium-screens) {
        margin-bottom: $mobile;
    }

    @include for(medium-screens) {
        margin-bottom: $desktop;
    }
}

@mixin body-text() { 
    font-family: $text; 
    font-weight: 400;
    
    @include upto(medium-screens) {
        font-size: $font-size-mobile;
        letter-spacing: -0.01em;
        line-height: 1.5;
    }

    @include for(medium-screens) {
        font-size: $font-size-base;
        letter-spacing: -0.01em;
        line-height: 1.6;
    }
}


@mixin ultra-small-text() {
    font-family: $text; 
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
}

@mixin h1-title() { // title
    font-family: $text;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.01em;

    @include upto(large-screens) {
        font-size: 27px;
    }
}

@mixin h2-title() { // section
    font-family: $text;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;

    @include upto(large-screens) {
        font-size: 21px;
    }
}

@mixin h3-title() { // projects 
    font-family: $text;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;

    @include upto(large-screens) {
        font-size: 15px;
    }
}

@mixin h4-title() { // slogan
    font-family: $text;
    font-size: 21px;
    font-weight: 600;
    line-height: 1.3;

    @include upto(large-screens) {
        font-size: 18px;
        letter-spacing: -0.01em;
    }
}

@mixin h5-title() { // subtitle
    font-family: $text;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    text-transform: uppercase;

    @include upto(large-screens) {
        font-size: 12px;
    }
}

@mixin h6-title() { // format
    font-family: $text;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;

    @include upto(large-screens) {
        font-size: 14px;
    }
}


@mixin sitenav-link() {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    text-transform: lowercase;
    letter-spacing: 0.01em; 
}

// account 
@mixin form-title() {
    font-family: $text;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;    
}

@mixin form-text() {
    font-family: $text;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;    
}

@mixin side-text() {
    font-family: $text;
    font-size: 14px;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: -0.02em;
}

@mixin utility-text() {
    font-family: $text;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
}


// home
@mixin home-text-base() {
    font-family: $text; 
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 1.5;
    
    @include upto(medium-screens) {
        font-size: 16px;
    }

    @include for(medium-screens) {
        font-size: 16px;
    }
}

@mixin home-text-large()  {
    font-family: $text; 
    font-weight: 300;
    letter-spacing: -0.01em;
    line-height: 1.6;
    
    @include upto(medium-screens) {
        font-size: 17px;
    }

    @include for(medium-screens) {
        font-size: 24px;
    }
}

@mixin home-text-small()  {
    font-family: $text; 
    font-weight: 400;
    line-height: 1.5;
    
    @include upto(medium-screens) {
        font-size: 12px;
    }

    @include for(medium-screens) {
        font-size: 14px;
    }
}


@mixin home-section-title() {
    font-family: $text;
    font-size: 33px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.01em;

    @include upto(large-screens) {
        font-size: 27px;
    }
}
