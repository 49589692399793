.author-donation {
    @include site-content-gap-no-feature();

    &__error {
        @include theme-background-color('background-placeholder');
        border-top: 1px solid;
        border-bottom: 1px solid;
        @include theme-border-color('border-placeholder');
        padding: $base*2;
        margin-top: $base*6;
    }

    &__buttons {
        @include upto(large-screens) {
            display: flex;
            margin: 20px 0 15px;
        }

        @include for(large-screens) {
            display: none;
        }
    }

    &__support-button {
        display: block;
        width: 167px;
        height: 36px;
        margin-right: 20px; // dirty
        padding: 11px;

        font-weight: 600;
        font-size: 13px;
        line-height: 1;
        font-family: $text;
        text-transform: lowercase;
        text-align: center;
        border-radius: 18px;

        @include button-gradient-with-hover();
    }

    &__qr {
        margin-top: 50px;
    }
}

.author-page-grid {
    $cur_root: author-page-grid;

    .#{$cur_root}__separator-1 {
        border-bottom: 1px dashed $color-primary;
    }

    // Desktop styles

    @include for(large-screens) {
        display: grid;
        grid-template-columns: [start] repeat(12, 1fr) [main-end] 90px [side-start] 36.4% [end];
        grid-template-rows: [start] repeat(10, min-content) [end];

        .#{$cur_root}__separator-1 {
            grid-row: 3;
            grid-column: start / main-end;

            padding-top: 55px;
        }

        .#{$cur_root}__intro {
            grid-row: 4;
            grid-column: start / main-end;

            padding-top: 55px;
        }

        .#{$cur_root}__profile-second {
            grid-row: 5;
            grid-column: start / main-end;

            padding-top: 40px;
        }

        .#{$cur_root}__bottom {
            grid-row: 6;
            grid-column: start / main-end;

            padding-top: 60px;
        }

        .#{$cur_root}__side {
            grid-row: start / end;
            grid-column: side-start / end;
        }
    }

    &--has-projects {

        @include for(large-screens) {
            .#{$cur_root}__avatar {
                grid-row: 1;
                grid-column: start / 5;
            }

            .#{$cur_root}__projects {
                grid-row: 1;
                grid-column: 6 / main-end;

                padding-top: 30px;
                padding-left: 22px;
            }

            .#{$cur_root}__profile {
                grid-row: 2;
                grid-column: start / main-end;

                padding-top: 33px;
            }
        }
    }

    &--no-projects {

        @include for(large-screens) {
            .#{$cur_root}__avatar {
                grid-row: 1;
                grid-column: start / 5;
            }

            .#{$cur_root}__profile {
                grid-row: 1;
                grid-column: 6 / main-end;

                padding-top: 30px;
                padding-left: 22px;
            }
        }

    }


    // Mobile/tablet layout is common for "has/no" projects cases

    @include upto(large-screens) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: [start] repeat(10, min-content) [end];

        .#{$cur_root}__avatar {
            grid-row: 1;
        }

        .#{$cur_root}__profile {
            grid-row: 2;
            padding-top: 33px;
        }

        .#{$cur_root}__separator-1 {
            display: none;
        }

        .#{$cur_root}__intro {
            grid-row: 4;
            padding-top: 55px;
        }

        .#{$cur_root}__profile-second {
            grid-row: 5;
            padding-top: 40px;
        }

        .#{$cur_root}__projects {
            grid-row: 6;
            padding-top: 50px;
        }

        .#{$cur_root}__bottom {
            grid-row: 8;
            padding-top: 60px;
        }

        .#{$cur_root}__side {
            grid-row: 7;
            padding-top: 50px;
        }
    }
}


.author-projects {
    position: relative;
    
    &__title {
        @include h2-title();

        margin-bottom: $base*4;
        font-size: 18px !important;
        text-transform: uppercase;
    }
}

/*
A grid row.
Contains blocks: .author-links, .journal-item
*/
.author-intro {
    @include for(medium-screens) {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        &__col-1 {
        }

        &__col-2 {
            max-width: 265px;
        }
    }

    @include upto(medium-screens) {
        &__col-1 {

        }

        &__col-2 {
            max-width: 350px;
            margin: 0 auto;
        }

        &__col-1 + &__col-2 {
            margin-top: 50px;
        }
    }
}

/* Displays author's public links */
.author-links {
    &__title {
        @include h2-title();

        line-height: 1; // initial: 28.8px | 120%
        //letter-spacing: -0.16px;

        margin-bottom: 21px;
    }

    &__list {
    }

    &__item {
        font-size: 16px;
        font-weight: 400;
        line-height: 25.6px; /* 160% */
        letter-spacing: -0.16px;

        margin-bottom: 7px;
    }

    &__item-title {
    }

    &__item-link {
        color: $brown;
    }
}


/* Displays a journal article of an author */
.journal-item {
    border: 1px solid;
    padding: 15px 8px 18px 23px;
    position: relative;

    border-radius: 12px;
    @media (prefers-color-scheme: light) {
        border-color: rgba(226, 223, 219, 0.63); // #E2DFDBA1

    }
    @media (prefers-color-scheme: dark) {
        border-color: rgb(87 87 87 / 63%); // #575757A1
    }

    &__sibtitle{
        max-width: 480px;
        margin-bottom: 6px;

        color: $green;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: -0.16px;
    }

    &__title {
        display: block; // coz link

        @include theme-text-color('text-body');
        font-size: 15px;
        font-weight: 500;
        line-height: 20px; /* 133.333% */
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        position: absolute;
        right: -10px;
        top: -16px;

        border-radius: 50%;
        @include theme-background-color('background-side-block'); // initial: #F2F1EF

        img { // original size: 36x44
            width: 20px;
            height: auto;
        }
    }
}
