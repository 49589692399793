/** common elements for donation related pages */

// supporters block
.supporters-block {
    position: relative;

    &:before {
        content: '';
        width: 6px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @include brand-gradient-vertical();
        border-radius: 3px;
    }

    &__title {
        a {
            @include primary-link();
        }
    }

    &__title--author {
        color: $color-primary;
        font-weight: 500;
    }

    &__title--launched {
        color: $color-primary;
        font-weight: 500;
    }

    @include upto(large-screens) {
        padding-left: 36px;
        
        &__title {
            font-size: 17px;
            margin-top: $base*3;
        }
    }

    @include for(large-screens) {
        padding-left: 36px;

        &__title {
            font-size: 18px;
            margin-top: $base*5;
        }
    }
}


.figure-set {

    @include upto(large-screens) {

        &__number {
            font-size: 60px;
            line-height: 60px;
            margin-bottom: $base;
        }

        &__label {
            font-size: 21px;
            line-height: 21px;
        }
    }

    @include for(large-screens) {
        &__number {
            font-size: 70px;
            line-height: 70px;
            margin-bottom: $base;
        }

        &__label {
            font-size: 21px;
        }
    }
}

.scale {
    height: 6px;
    border-radius: 3px;
    @include theme-background-color('scale-base');

    &__progress {
        height: 100%;
        border-radius: 3px;
        @include brand-gradient();
    }
}


.close-trigger {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    text-transform: lowercase;

    .svg-icon {
        width: 9px;
        height: 9px;
        margin-left: 2px;
        transform: rotate(-90deg);
        @include theme-fill-color('text-body');
    }
}



