
.link1 {
    color: $color-link;
    text-decoration: underline;
}

.link2 {
    color: $color-brown;
    text-decoration: underline;
}


/**
Used in: Home -> FAQ section
 */
.link3 {
    display: inline-block;
    color: $color-primary !important; // f*** chrome
    transition: .35s;

    &:hover {
        color: $color-link;

        .svg-icon {
            fill: $color-link;
        }
    }

    .svg-icon {
        fill: $color-primary;
        width: 10px;
        height: 10px;
        margin-left: 6px;
        transition: .32s;
    }
}
