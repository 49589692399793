/** popup */
@mixin projects-grid($item_class:'item') {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    margin-left: -20px;

    .#{$item_class} {
        
    }

    @include upto(medium-screens) {
        flex-flow: row nowrap;

        .#{$item_class} {
            width: calc(50% - 20px);
        }
    }

    @include for(medium-screens) {
        .#{$item_class} {
            width: 33.33%;
        }
    }
}

.listpop {
    &__list {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        @include mq_between($bp_355, $bp_medium) {
            gap: $base*3;
        }

        @include for(medium-screens) {
            gap: $base*6;
        }
    }

    &__trigger {
        width: 90px;
        cursor: pointer;
    }

    &__popup {
        position: absolute;
        @include theme-background-color('background-popup');
        min-height: 100%;
        padding: $base*4 ;
        z-index: 100;

        &.loading:before {
            content: 'Момент...';
            font-size: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @include upto(medium-screens) {
            top: 0; 
            width: calc(100% + 60px);
            margin-left: -30px;
        }

        @include between(medium-screens, large-screens) {
            position: absolute;
            width: 100%;
            height: 480px;
            top: -20px;
            left: 0;
            border-radius: 9px;
        }

        @include for(large-screens) {
            position: absolute;
            right: 0;
            width: 710px;
            height: 480px;
            top: -50px;
            border-radius: 9px;
        }
    }

    &__close {
        cursor: pointer;

        width: 20px;
        height: 24px;

        position: absolute;
        top: 10px;
        right: 10px;

        .icon-close {
            width: 13px;
            height: 13px;
            fill: $color-brown;
            margin-top: 8px;
        }
    }

    &__title {
        @include h3-title();
        @include theme-text-color('text-body');
        margin-bottom: 20px;
    }

    .loading &__popup-in {
        opacity: 0;
    }

    &__popup-in {
        width: 100%;
        overflow: hidden;
    }

    &__grid {
        @include projects-grid('embla__slide');
        overflow-x: auto;
    }
}



