/* "QR-code" block on Author/Campaign public pages */
.public-qr {
    --image-size: 110px;

    padding: 24px 24px 24px 38px;
    border-radius: 25px;
    @include theme-background-color('background-donation');

    @include for(small-screens) {
        display: flex;
        justify-content: space-between;
        gap: 40px;
    }

    &__col-1 {
        @include for(small-screens) {
            flex-grow: 1;
            flex-shrink: 1;
        }
    }

    &__col-2 {
        @include for(small-screens) {
            display: flex;
            justify-content: right;

            flex-grow: 2;
            flex-shrink: 0;
            width: var(--image-size);
            margin-left: auto;
        }
    }

    &__title {
        margin-top: 5px;

        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.18px;
    }

    &__desc {
        margin-top: 10px;

        font-size: 13.5px;
        font-weight: 400;
        line-height: 22px; /* 162.963% */
        letter-spacing: -0.405px;
    }

    &__link-wrapper {
        @include for(small-screens) {
            margin-top: 5px;
        }

        @include upto(small-screens) {
            margin-top: 12px;
        }
    }

    &__link {
        color: $brown;

        font-size: 13.5px;
        font-weight: 500;
        letter-spacing: -0.405px;

        text-transform: lowercase;
    }

    &__image-wrapper {
        width: var(--image-size);
        position: relative;
        user-select: none;

        @include upto(small-screens) {
            margin-top: 20px;
            left: -10px;
        }
    }

    &__image-cover {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
    }

    &__image {
        display: block;
        width: var(--image-size);
        height: var(--image-size);
        position: relative;
        z-index: 5;
    }
}
