.action-avatar {
    text-align: center;
    

    &:not(&--empty) {
        cursor: pointer;
    }

    &__img {
        margin: 0 auto;
        border-radius: 50%;
        padding: 4px;
        border: 1px solid $color-border-regular;
        transition: all .35s;

        &:hover {
            transform: scale(1.025);
            box-shadow: 1px 1px 5px rgba(150, 150, 150, 0.15);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%; 
        }
    
        @include upto(medium-screens) {
            width: 80px;
            height: 80px;
        }

        @media screen and (max-width: 360px) {
            width: 70px;
            height: 70px;
        }

        @include for(medium-screens) {
            width: 90px;
            height: 90px;
        }
    }

    .placeholder &__img:hover {
        transform: scale(1);
        box-shadow: 0 0 0 transparent;
        cursor: default;
    }

    &__placeholder {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: transparentize($color-grey, 0.5);
    }

    &--open &__img {
        border-color: $color-primary;
    }

    &--empty &__img {
        border-color: $color-border-regular;
    }

    &--closed &__placeholder {
        background: transparentize($color-brown, 0.75);
    }

    &--finished &__placeholder {
        background: transparentize($color-primary, 0.5);
    }

    &__title {
        margin-top: $base*1.5;
        text-transform: uppercase;
        font-size: 74%;
        font-weight: 500;
    }

    &--empty &__title {
        opacity: 0.5;
        font-weight: 400;
    }

    &__count {
        color: $color-primary;
        margin-left: 3px;
    }
}
