/* slider */
.embla {
    position: relative;
    max-width: 700px;
    margin: 0 auto;

    &__viewport {
        overflow: hidden;
        width: 100%;
    }

    &__container {
        display: flex;
        user-select: none;
        margin-left: -20px;
        width: 100%;
    }

    &__slide {
        position: relative;

        @include upto(medium-screens) {   
            min-width: calc(50% + 10px);
            max-width: calc(50% + 10px);
            padding-left: 20px;
        } 

        @include for(medium-screens) {
            min-width: 33.34%;
            max-width: 33.34%;
            padding-left: 20px;
        } 
    }

    &__slide-inner {
        position: relative;
        overflow: hidden;
        min-height: 195px;
    }

    &__button-row {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
    }

    &__button {
        margin-top: $base*4;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid $color-primary;
        background: transparent;
        cursor: pointer;
        transition: background .35s;

        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;

        &:hover {
            background: transparentize($color-primary, 0.7);
        }

        &:disabled {
            cursor: default;
            opacity: 0.3;
        }

        .svg-icon {
            width: 12px;
            height: 12px;
            @include theme-fill-color('border-regular-base');
        }

        &--prev {
            .svg-icon {
                transform: rotate(180deg);
            }

            margin-right: $base;
        }

        &--next {
            .svg-icon {
                
            }
        }
    }
}


.embla-wide {
    position: relative; 
    width: 100%;

    &__viewport {
        overflow: hidden;
        width: 100%;
    }

    &__container {
        display: flex;
        user-select: none;
        margin-left: -20px;
        width: 100%;
    }

    &__slide {
        @include upto(large-screens) {
            min-width: calc(50% + 10px);
            max-width: calc(50% + 10px);
            padding-left: 20px;
        }

        
        @include for(large-screens) {
            min-width: calc(20% + 5px);
            max-width: calc(20% + 5px);
            padding-left: 20px;
        } 
    }

    &__slide-inner {
        position: relative;
        overflow: hidden;
        min-height: 195px;
    }

    &__button-row {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;

        position: absolute;
        
        height: 1px;
        z-index: 5; 
        left: 50%;
        top: 75px;
        transform: translateX(-50%);

        @include upto(medium-screens) { 
            width: calc(100% + 60px);
        }

        @include between(medium-screens, large-screens) { 
            width: calc(100% + 90px);
            top: 130px;
        }

        @include for(large-screens) {
            width: calc(100% + 90px);
            &.hide-on-large {
                display: none;
            }
        }
    }

    &__button {
        width: 36px;
        height: 36px;
        background: transparent;
        cursor: pointer;
        transition: background .35s;

        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 0;

        &:disabled {
            cursor: default;
            opacity: 0.3;
        }

        .svg-icon {
            width: 36px;
            height: 36px;
            fill: $color-primary
        }

        &--prev {
            left: 0;

            .svg-icon {
                transform: rotate(180deg);
            }
        }

        &--next {
            right: 0;
        }
    }
}
