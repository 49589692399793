// Bundler
:root {
    // Changes dynamically via javascript
    --scroll-bar-width: 0px;
}

@import 'fonts';
@import 'colors';
@import 'colors-legacy';
@import 'presets';
@import 'typo';
@import 'mixins';
@import 'utils';

@import 'reset';
@import 'base';
@import 'layout'; 


//includes 
@import 'includes/alerts';
@import 'includes/buttons';
@import 'includes/the-content';
@import 'includes/fields-base';
@import 'includes/fields-input';
@import 'includes/fields-checkbox';
@import 'includes/django_forms_plus';


// elements 
@import 'elements/action-avatar';
@import 'elements/author-avatar';
@import 'elements/banners';
@import 'elements/buttons';
@import 'elements/cards';
@import 'elements/headers';
@import 'elements/links';
@import 'elements/lists';
@import 'elements/logo';
@import 'elements/paging';
@import 'elements/sliders';
@import 'elements/tooltip';
@import 'elements/sharing';
@import 'account/elements';


// components 
@import 'components/header';
@import 'components/user-header';
@import 'components/footer';


// modules 
@import 'modules/temp';
@import 'modules/donation-base';
@import 'modules/donation-form';
@import 'modules/author-elements';
@import 'modules/author-slider';
@import 'modules/author-support';
@import 'modules/author';
@import 'modules/author-campaign-popup';
@import 'modules/campaign-elements';
@import 'modules/campaign-support';
@import 'modules/campaign';
@import 'modules/campaign-card';
@import 'modules/campaign-slider';
@import 'modules/cancel_s10n_pages';
@import 'modules/qr-public';
@import 'modules/custom-campaign';

// logic
@import 'account/form';

// pages 
@import 'pages/general';
@import 'pages/error';
@import 'pages/home';
@import 'pages/search';
@import 'pages/thankyou';
@import 'pages/authors-catalog';
@import 'pages/sharing';
