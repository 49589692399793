/*  fields */
.account-form {

    &__errors {
        margin-bottom: $base*4;

        .alert {
            margin: 0;
        }
    }

    &__submit {
        margin-top: $base*6;
    }

    &__disclaymer {
        margin-top: $base*2;
    }

    &__fieldset {
        margin-bottom: $base*7;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.form-fieldset, .dfp-fieldset {

    // section title
    &__legend, .dfp-fieldset__title {
        @extend .header-style1;
    }

    // section description
    &__legend-details, .dfp-fieldset__desc {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: $base*3;
        margin-top: -1*$base;
        @include theme-text-color('text-semi');

        a {
            @include utility-link();
        }
    }

    .columns-3 {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .form-group {
            width: 30%;
            margin-top: 0;

            label {
                white-space: nowrap;
                @include upto(medium-screens) {
                    font-size: 13px;
                }
            }
        }
    }

    .amounts {
        .form-group {
            margin-bottom: 0;
        }
    }
}
