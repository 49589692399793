.author-avatar { 

    border: 1px solid;
    @include theme-border-color('primary');
    border-radius: 50%;
 
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }

    &__placeholder { 
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: relative;
        @include theme-background-color('background-donation');
        .svg-icon {
            width: 50%;
            height: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include theme-fill-color('scale-base');
        }
    }

    @include upto(medium-screens) {
        padding: 6px;
        width: 170px;
        height: 170px;

        margin-bottom: $base*3;
    }

    @include for(medium-screens) {
        width: 218px;
        height: 218px;
        padding: 8px;

        margin-bottom: 33px;
    }

    // the style for dead authors
    &--sorrow {
        @include for-light-theme() {
            border-color: $color-black;
        }

        @include for-dark-theme() {
            border-color: #111;
            background-color: #111;
        }
    }
}
