.search-page {
    max-width: 655px;
    margin-top: 35px; // dirty

    &__form {
        margin-bottom: 60px;
    }

    &__results {}

    &__item {
        margin-bottom: 50px;
    }
}

.search-form {
    $row-height: 20px;

    display: flex;
    align-items: center;
    border: 1px solid transparent;
    @include theme-border-color('border-donation');
    padding: 12px $row-height 10px;

    border-radius: 22px;

    &__input {
        display: block;
        width: 90%;

        position: relative;
        left: -2px; // pixel perfect
        top:-1px; // pixel perfect

        @include theme-text-color('text-body');
        font-weight: 500;
        font-size: 13px;
        line-height: 1;
        font-family: $ff-main;
        letter-spacing: 0.13px;
        background-color: transparent;

        &:focus-visible {
            outline: 0;
        }
    }

    &__submit {
        width: $row-height;
        height: $row-height;
        margin-left: auto;

        position: relative;
        top: -1px;
        right: -1px;

        background-color: transparent;

        svg {
            width: $row-height;
            height: $row-height;
            @include theme-stroke-color('border-regular-base');
            transition: all .35s;
        }
    }
}

.search-item {
    display: flex;

    &__thumb-wrapper {
        flex-shrink: 0; // if "info" has long URLs it squeezes "thumbnail". This hack fixes that.
        width: 80px;
        height: 80px;

        @include mq_upto($bp_small) {
            margin-right: 20px;
        }

        @include mq_for($bp_small) {
            margin-right: 38px;
        }
    }

    &__thumb {
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    &__empty-thumb {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        @include theme-text-color('background-tint'); // initial f0f0f0
    }

    &__info {
        flex-shrink: 2000; // if "info" has long URLs it squeezes "thumbnail". This hack fixes that.
        padding-top: 15px;
    }

    &__title {
        margin-bottom: 4px;

        @include theme-text-color('text-body');
        font-weight: 600;
        font-size: 21px;
        line-height: 27.3px; /* 130% */
        font-family: $ff-main;
        letter-spacing: -0.16px;
    }

    &__desc {
        @include theme-text-color('text-body');

        font-weight: 400;
        font-size: 16px;
        line-height: 24px; /* 150% */
        font-family: $ff-main;
        letter-spacing: -0.16px;

        word-break: break-word; // long URLs

        @include mq_upto($bp_small) {
            margin-right: -5px;
        }
    }
}

.icon-search {
    stroke-width: 2px;
    fill-opacity: 0;
}
