/* elements */

.agree-disclaymer {
    font-size: 12px;
    a {
        @include theme-text-color('text-semi');
        color: inherit;
        text-decoration: underline;
    }
}

.back-link {
    @include utility-link();
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    line-height: 15px;

    span {
        display: inline-block;
        line-height: 15px;
        margin-left: 9px;
    }

    .svg-icon {
        width: 12px;
        height: 12px;
        fill: currentColor;
        transform: rotate(180deg);
    }
}

.finished-link {
    @include form-field-border();
    @include theme-background-color('background-field-focus');
    color: $brown;
    width: 100%;
    padding: 8px 12px;
    line-height: 20px;
    a {
        color: $brown;
    }
}

.danger {
    color: $red;
}

.success {
    color: $color-primary;
}


.checklist {
    list-style: none;
    counter-reset: steps;
    margin-top: $base*2;
    font-size: 0.9em;

    li {
        counter-increment: steps;
        margin-bottom: $base;
        padding-left: 24px;
        position: relative;

        &:before {
            content: counter(steps);
            background: $brown;
            color: $white;

            position: absolute;
            top: 4px;
            left: -1px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: inline-grid;
            place-items: center;
            font-size: 11px;
            font-weight: 500;
            line-height: 16px;
        }

        a {
            @include side-link();
        }
    }
}

.checklist2 {
    $fz: 13.5px;

    margin-top: $base*2;

    list-style: none;
    counter-reset: steps;
    font-weight: 400;
    font-size: $fz;
    line-height: 22px;
    font-family: $ff-main;
    letter-spacing: -0.03em;

    li {
        margin-bottom: 10px;
        padding-left: 30px;
        position: relative;

        counter-increment: steps;

        &:before {
            content: counter(steps);

            display: inline-grid;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0;
            left: 0;

            color: $white;
            font-size: $fz;
            font-weight: 600;
            line-height: 1;
            font-family: $ff-main;
            letter-spacing: -0.02em;

            background: $brown;
            place-items: center;
            border-radius: 50%;
        }

        a {
            @include side-link();
        }
    }
}

.checklist3 {
    border-radius: 12px;
    @include theme-background-color('background-side-block');
    font-family: $ff-main;


    @include mq_upto($bp_small) {
        padding: 26px;
    }

    @include mq_for($bp_small) {
        padding: 35px 45px;
    }

    &__row {
        display: flex;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__row-key {
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        letter-spacing: -0.01em;

        @media (mq_part_upto($bp_370)) {
            min-width: 120px;
        }

        @media (mq_part_between($bp_370, $bp_small)) {
            min-width: 140px;
        }

        @include mq_for($bp_small) {
            min-width: 180px;
        }
    }

    &__row-value {
        display: flex;
        align-items: center;
    }

    &__correct-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 10px;

        svg {
            width: 16px;
            height: 16px;
            fill: $color-primary;
        }
    }

    &__correct-text {
        color: #25C7CF;

        font-size: 13px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: -0.01em;
    }

    &__error-link {
        color: #EB5E28;
        font-weight: 600;
        font-size: 13px;

        letter-spacing: -0.01em;
        text-decoration: underline;

        @include mq_upto($bp_large) {
            line-height: 1.2;
        }

        @include mq_for($bp_large) {
            line-height: 1;
        }
    }
}


.info-text .checklist {
    margin: $base*3 0;

    a {
        @include side-link();
    }
}

.info-text--warning {
    color: $red;
    a {
        color: inherit;
        text-decoration: underline;
    }
}


.sharing-call {
    border-radius: 12px;
    background: transparentize($blue, 0.9);

    @include upto(medium-screens) {
        padding: 20px;
    }

    @include for(medium-screens) {
        padding: 27px 33px;
    }

    &__desc {
        margin-bottom: $base*4;
    }

    &__button {
        @include aside-button-base();

        border-color: $color-link;
        padding: 9px 17px;
        color: $color-link;

        font-weight: 600;
        font-size: 84.4%; // 13.5px
        line-height: 1;
        letter-spacing: -0.02em;

        &:hover {
            background-color: rgba($color-link, 0.075);
        }
    }
}
