
// Used in: tags list (authors_catalog)
.links-list-1 {
    $_link_color_light: #3A4B4B;
    $_link_color_dark: transparentize($white, 0.25);

    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    font: 500 16px/1 $ff-main;

    @include mq_upto($bp_medium) {
        font-size: 15px;
    }

    &__item {
        border-bottom: 1px solid;
        @include light_dark($prop--border-color, $_link_color_light, $_link_color_dark);
        padding-bottom: 4px;

        @include light_dark($prop--color, $_link_color_light, $_link_color_dark);

        &--active {
            color: $color-link;
            border-color: $color-link;
        }
    }
}

.table-list-1 {
    border: 2px solid #000;
    @include light_dark($prop--border-color, #F5F5F5, #2A2E30);
    border-radius: 24px;
    overflow: hidden;
    box-sizing: content-box;

    &__row {
        display: flex;
        align-items: stretch;
        width: 100%;

        @include mq_upto($bp_medium) {
            padding: 25px;
        }

        @include mq_for($bp_medium) {
            padding: 30px;
        }

        &:nth-child(2n) {
            @include theme-prop($prop--background-color, $key--background-body);
        }

        &:nth-child(2n - 1) {
            @include light_dark($prop--background-color, #F5F5F5, #2A2E30)
        }
    }

    &__col {
        display: flex;
        align-items: center;
    }
}


.author-row-1 {
    @include mq_upto($bp_medium) {
        flex-wrap: wrap;
    }

    &__col-avatar {
    }

    &__avatar-link {
        display: block;
        max-width: 80px;
        width: 100%;
        height: auto;
    }

    &__avatar {
        display: block;
        max-width: 80px;
        width: 100%;
        border-radius: 40px;

        a {
            display: block;
        }
    }

    &__col-title {
        font: 500 18px/1.2 $ff-main;

        a {
            @include theme-prop($prop--color, $key--text-body);
        }
    }

    &__col-desc {
        font: 14px/18.2px $ff-main;
    }

    &__col-tags {
        color: #969492;
        font: 500 14px/18.2px $ff-main;
        text-transform: lowercase;
    }

    &__col-projects {
        font: 14px/18.2px $ff-main;
    }

    &__col-links {
    }

    // (16 + 2) + (24 + 2) + (35 + 2) + (19)
    @include mq_upto($bp_medium) {
        &__col-avatar {
            width: 80px;
            margin-right: 7%;
        }
        &__col-title {
            width: calc(100% - (80px + 7%));
        }
        &__col-desc {
            width: 100%;
            margin-top: 20px;
        }
        &__col-tags {
            display: none;
        }
        &__col-projects {
            display: none;
        }
        &__col-links {
            width: 100%;
            margin-top: 20px;

            &--empty {
                display: none;
            }
        }
    }


    // (16 + 2) + (24 + 2) + (35 + 2) + (19)
    @include mq_between($bp_medium, $bp_large) {
        &__col-avatar {
            width: 16%;
            margin-right: 2%;
        }
        &__col-title {
            width: 24%;
            margin-right: 2%;
        }
        &__col-desc {
            width: 35%;
            margin-right: 2%;
        }
        &__col-tags {
            display: none;
        }
        &__col-projects {
            display: none;
        }
        &__col-links {
            width: 19%;
        }
    }

    // (7 + 1.5) + (22 + 2) + (19 + 2) + (19 + 2) + (12) + (12) = 98.5
    @include mq_for($bp_large) {
        &__col-avatar {
            flex-basis: 7%;
            width: 7%;
            margin-right: 1.5%;
        }
        &__col-title {
            width: 22%;
            margin-right: 2%;
        }
        &__col-desc {
            width: 19%;
            margin-right: 2%;
        }
        &__col-tags {
            width: 19%;
            margin-right: 2%;
        }
        &__col-projects {
            width: 12%;
        }
        &__col-links {
            width: 12%;
        }
    }
}
