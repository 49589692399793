/** checkbox styles here **/
.form-check {
    position: relative;
    margin: $base*2 0;
    padding-left: 27px;

    font-size: 12px;
    line-height: 1.4;

    &__error {
        color: $red;
        margin-top: 3px;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    input {
        position: absolute;
        opacity: 0;

        &:hover {
            cursor: pointer;
        }
    }

    label {
        display: inline-block;
        position: relative;
        pointer-events: all;
        user-select: none;

        @include ultra-small-text();
        line-height: 1.7;

        &:before {
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: transparent;
            
            position: absolute;
            top: 0;
            left: -27px;
            cursor: pointer;

            border: 1px solid;
            @include form-field-border-color();
        }

        &:hover {
            cursor: pointer;
        }
    }

    input:checked + label {
        &:before {
            border-color: $color-primary;
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxMHB4IiB2aWV3Qm94PSIwIDAgMTAgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+UGF0aDwvdGl0bGU+CiAgICA8ZyBpZD0iZnJvbS11cGRhdGUiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIzMC1BdXRob3ItZm9ybS0tY29taXNzaW9uMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTgyOC4wMDAwMDAsIC00ODEuMDAwMDAwKSIgZmlsbD0iIzJCRDdFMCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik04MzEuODQ3OTI2LDQ5MC44NTAyMyBDODMyLjIzOTYzMSw0OTAuODUwMjMgODMyLjU1MDY5MSw0OTAuNjY5NzM5IDgzMi43ODExMDYsNDkwLjMwODc1NiBMODM3LjczNTAyMyw0ODIuNTc4MzQxIEM4MzcuNzk2NDY3LDQ4Mi40NjMxMzQgODM3Ljg1NTk5MSw0ODIuMzQ0MDg2IDgzNy45MTM1OTQsNDgyLjIyMTE5OCBDODM3Ljk3MTE5OCw0ODIuMDk4MzEgODM4LDQ4MS45NzU0MjIgODM4LDQ4MS44NTI1MzUgQzgzOCw0ODEuNTk5MDc4IDgzNy45MDIwNzQsNDgxLjM5MzYyNSA4MzcuNzA2MjIxLDQ4MS4yMzYxNzUgQzgzNy41MTAzNjksNDgxLjA3ODcyNSA4MzcuMjkzMzk1LDQ4MSA4MzcuMDU1Myw0ODEgQzgzNi43MzI3MTksNDgxIDgzNi40NjAwNjEsNDgxLjE3NjY1MSA4MzYuMjM3MzI3LDQ4MS41Mjk5NTQgTDgzMS44MTMzNjQsNDg4LjYwMzY4NyBMODI5LjczOTYzMSw0ODUuOTMwODc2IEM4MjkuNjA5MDYzLDQ4NS43Njk1ODUgODI5LjQ3NjU3NSw0ODUuNjU2Mjk4IDgyOS4zNDIxNjYsNDg1LjU5MTAxNCBDODI5LjIwNzc1Nyw0ODUuNTI1NzMgODI5LjA2Mzc0OCw0ODUuNDkzMDg4IDgyOC45MTAxMzgsNDg1LjQ5MzA4OCBDODI4LjY0OTAwMiw0ODUuNDkzMDg4IDgyOC40MzIwMjgsNDg1LjU4MzMzMyA4MjguMjU5MjE3LDQ4NS43NjM4MjUgQzgyOC4wODY0MDYsNDg1Ljk0NDMxNiA4MjgsNDg2LjE2MTI5IDgyOCw0ODYuNDE0NzQ3IEM4MjgsNDg2LjY1Mjg0MiA4MjguMDkyMTY2LDQ4Ni44OTA5MzcgODI4LjI3NjQ5OCw0ODcuMTI5MDMyIEw4MzAuODgwMTg0LDQ5MC4zMDg3NTYgQzgzMS4wMjYxMTQsNDkwLjUwMDc2OCA4MzEuMTc1ODgzLDQ5MC42MzkwMTcgODMxLjMyOTQ5Myw0OTAuNzIzNTAyIEM4MzEuNDgzMTAzLDQ5MC44MDc5ODggODMxLjY1NTkxNCw0OTAuODUwMjMgODMxLjg0NzkyNiw0OTAuODUwMjMgWiIgaWQ9IlBhdGgiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
            background-repeat: no-repeat;
            background-size: 10px 10px;
            background-position: 3px 3px;
        }
    }

    &.invalid label:before {
        border-color: $red;
    }

    &__error {
        @include form-error-text();
        padding: 3px 0 0 3px;
    }
}

