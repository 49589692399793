/** Error pages **/

.error-page {
	height: 70vh;
	padding-top: $base * 8;

	&__title {
        @include h1-title();
    }

    &__header {
        margin-bottom: $base*3;
    }

	&__content {

	}
}
