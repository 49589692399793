$sharing-border-color: rgba(34, 38, 40, 0.3);


.sharing-page {
    max-width: 808px; // TODO

    &__share {
        margin-bottom: 52px;
    }

    &__copy_content {
        margin-bottom: 16px;
    }

    &__qrcode {
        margin-bottom: 60px;
    }

    &__website {
        margin-bottom: 60px;
    }

    &__not-allowed-msg {
        @include main-text-content();
    }
}

.copy-content-2 {
    padding: $base*2 $base*3;
    border: 1px solid;
    position: relative;

    @include theme-border-color('border-neutral');

    &__content {
        padding-right: 20px;
        user-select: all;
        height: 100%;
    }

    &--line &__content {
        // https://stackoverflow.com/questions/34294054/how-to-implement-single-line-ellipsis-with-css
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--multiline &__content {
        textarea {
            background: transparent;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;
        }
    }

    &__button-wrapper {
        position: absolute;
        top: 10px;
        right: 20px;
        width: 135px;
        height: 32px;
        margin-left: auto;
        user-select: none;
    }

    &__button {
        position: absolute;
        right: 0;
        font-weight: 600;
        @include theme-text-color('text-body');
    }

    &__success-msg {
        position: absolute;
        right: 0;
        font-weight: 600;
        color: $color-primary;
        white-space: nowrap;

        svg {
            display: inline-block;
            width: 16px;
            height: 16px;
            position: relative;
            top: 4px;
            fill: $color-primary;
        }
    }
}

.copy-content-3 {
    padding: 8px 5px;
    border: 1px solid;
    position: relative;

    @include theme-border-color('border-neutral');

    &__content {
        padding-right: 20px;
        user-select: all;
        height: 100%;

        font-size: 14px;
        line-height: 1;
        text-transform: lowercase;
        font-weight: 400;
        letter-spacing: -0.025em;
    }

    &--line &__content {
        // https://stackoverflow.com/questions/34294054/how-to-implement-single-line-ellipsis-with-css
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--multiline &__content {
        textarea {
            background: transparent;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;
        }
    }

    &__button-wrapper {
        position: absolute;
        top: 4px;
        right: 3px;
        width: 135px;
        height: 32px;
        margin-left: auto;
        user-select: none;
    }

    &__button {
        position: absolute;
        right: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 1;
        text-transform: lowercase;
        letter-spacing: -0.025em;
        @include theme-text-color('text-body');

        padding: 5px 10px;
        @include light_dark($prop--background-color, $color_white, $color-black);
    }

    &__success-msg {
        position: absolute;
        right: 0;

        font-weight: 600;
        font-size: 14px;
        line-height: 1;
        text-transform: lowercase;
        letter-spacing: -0.025em;
        color: $color-primary;
        white-space: nowrap;

        padding: 5px 10px;
        @include light_dark($prop--background-color, $color_white, $color-black);

        svg {
            display: inline-block;
            width: 10px;
            height: 10px;
            position: relative;
            top: 1px;
            fill: $color-primary;
        }
    }
}

.sharer {
    display: flex;
    align-items: stretch;

    &__item {
        flex: 1;
        transition: background .35s;
        border: 1px solid;
        @include theme-border-color('border-neutral');

        @include for(medium-screens) {
            margin-right: 16px;
        }

        @include upto(medium-screens) {
            margin-right: 10px;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            @include theme-background-color('background-slight');
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        height: 27px;
        border-radius: 50%;

        svg {
            display: block;
            max-width: 100%;
            max-height: 100%;
            height: auto;

            fill: #fff;
        }

        &--vk {
            background-color: rgba(0, 119, 251, 1);

            svg {
                width: 15px;
            }
        }

        &--tg {
            background-color: #20A8E7;;

            svg {
                width: 16px;
                position: relative;
                left: -1px;
                bottom: -1px;
            }
        }

        &--ok {
            background-color: #F0811D;

            svg {
                width: 10px;
            }
        }

        &--tw {
            background-color: #11A1EF;

            svg {
                width: 15px;
                position: relative;
                top: 1px;
            }
        }

        &--fb {
            background-color: #0D64C5;

            svg {
                width: 8px;
            }
        }
    }
}


.qrcode {
    @include for(small-screens) {
        display: flex;
        align-items: flex-start;
    }

    &__image-wrapper {
        border: 1px solid $sharing-border-color;
        @include for-dark-theme() {
             @include theme-border-color('border-regular-base')
        }

        position: relative;
        user-select: none;

        @include upto(small-screens) {
            max-width: 200px;
            margin-bottom: 20px;
        }

        @include for(small-screens) {
            width: 33%;
            margin-right: 8.5%;
            padding: 15px;
            box-sizing: border-box;
        }
    }

    &__image-cover {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
    }

    &__image {
        position: relative;
        z-index: 5;
    }

    &__info-wrapper {
        padding-top: 3px;

        @include for(small-screens) {
            width: 50%;
            box-sizing: border-box;
        }
    }

    &__desc {
        font-size: 13.5px;
        line-height: 22px;
        /* or 163% */

        letter-spacing: -0.03em;
    }

    &__button-wrapper {
        margin-top: 25px;
    }

    &__button {
        @include gradient-button-small();
        text-transform: none;
    }
}

.sharing-button-infos {
    padding-top: 5px;

    &__item {
        margin-bottom: 9px;
    }
}

.sharing-button-info {
    display: flex;
    align-items: center;
    border: 1px solid $sharing-border-color;
    padding: 13px;

    &__text {
        font-size: 100%;

        @include upto(medium-screens) {
            line-height: 1.25;
        }

        @include for(medium-screens) {
            line-height: 1;
        }
    }

    &__link-wrapper {
        display: block;
        margin-left: auto;
    }

    &__link {
        color: $color-primary;
        font-weight: 500;
        font-size: 84.4%; // 13.5 px
        line-height: 1;

        letter-spacing: -0.02em;
        text-transform: lowercase;
        white-space: nowrap;

        @include upto(medium-screens) {
            margin-left: 20px;
        }

        svg {
            display: inline-block;
            margin-left: 5px;
            width: 6px;
            height: 10px;
            position: relative;
            top: 0.5px;

            fill: $color-primary;
        }
    }
}

// Author account - "Sharing" page - "The code for a site" block
.website-share {
    margin-top: $base*4;
    padding: $base*5 $base*6;
    border-radius: 32px;
    @include theme-background-color('background-donation');

    display: grid;
    gap: 20px;

    @include for(medium-screens) {
        grid-template-columns: 1fr 312px;
    }

    &__code {
        .copy-content-2 {
            background: rgba(255,255,255, 0.9);

            textarea {
                font-size: 14px;
                opacity: 0.8;
            }

            // dirty hotfix, we need to rework "copy-content-2" to use in 2 places on the page
            .copy-content-2__button-wrapper {
                width: 155px;
                padding-right: 10px;
            }

            .copy-content-2__button,
            .copy-content-2__success-msg {
                top: 3px;
                right: 10px;
            }

            @include for-light-theme {
                .copy-content-2__button-wrapper {
                    background: rgba(255,255,255, 0.92);
                }
            }

            @include for-dark-theme {
                .copy-content-2__button-wrapper {
                    background: rgba(31,31,31, 0.83);
                }
            }

            @include upto(medium-screens) {
                height: 200px;
            }

            @include for(medium-screens) {
                height: 100%;
            }
        }
    }
}
