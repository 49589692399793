/* Header */
.site-header {
    position: relative;
    z-index: 5; // // higher than decor elements in "intro" block on Main page


    @include mq_upto($bp_large) {
        padding: 13px 0 17px;
    }

    @include mq_for($bp_large) {
        padding: $base*4.5 0;
    }

    &__row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }
}

// Desktop menu
.header-menu {
    $root: &;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    letter-spacing: normal;

    &--guest {
        @include mq_upto($bp_large) {
            display: none !important;
        }
    }

    &--user {
        @include mq_upto($bp_large) {
            #{$root}__item--journal,
            #{$root}__item--authors,
            #{$root}__item--search {
                display: none;
            }
        }
    }


    &__journal-link {
        display: inline-block;
        padding-bottom: 1px; // fixes tiny cutting of "у" character at the bottom (at least in chrome)

        font: 700 20px/1 $ff-main;
        letter-spacing: -0.01em;
        @include link-gradient();
    }

    &__desktop-link {
        display: block;
        color: inherit;
        font: 500 14px/1 $ff-main;
        white-space: nowrap;
        transition: all .35s;

        &:hover {
            color: $color-primary;
        }
    }

    &__button {
        display: block;
        border-width: 1px;
        border-style: solid;
        border-radius: 16px;
        padding-top: 7px;
        padding-bottom: 7px;

        color: inherit;
        font: 500 14px/1 $ff-main;
        white-space: nowrap;
        letter-spacing: -0.04em;
        transition: .35s;

        &:hover {
            color: $color-primary;
        }

        &--grey {
            border-color: transparentize(#B7AC9B, 0.5);

            &:hover {
                background: transparentize($beige2, 0.5);
                color: $color-black;
            }
        }

        &--blue {
            border-color: $green2;

            &:hover {
                background-color: $green2;
                color: $color-white;
            }
        }

        &--size-1 {
            padding-left: 18px;
            padding-right: 18px;
        }

        &--size-2 {
            padding-left: 27px;
            padding-right: 27px;
        }
    }

    &__item {
        &--journal {
            margin-right: 85px;
            position: relative;
            top: 1px;
        }

        &--authors {
            margin-right: 36px;
        }

        &--search {
            margin-right: 45px;
        }

        &--join {
            margin-right: 12px;

            .is-home & {
                display: none;
            }
        }

        &--login {
        }

        &--n10ns {
            @include mq_upto($bp_large) {
                 margin-right: 10px;
            }

            @include mq_for($bp_large) {
                 margin-right: 20px;
            }
        }

        &--finance {
            @include mq_upto($bp_large) {
                 margin-right: 10px;
            }

            @include mq_for($bp_large) {
                margin-right: 50px;
            }
        }
    }
}


// Mobile menu
.dropdown-menu {
    $parts-padding: 15px;

    position: relative;

    @include mq_for($bp_large) {
        display: none !important;
    }

    &__open-button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row;
        width: 36px;
        height: 36px;

        .icon-menu {
            width: 27px;
            height: 14px;
            @include theme-prop($prop--fill, $key--text-body);
        }
    }

    &__panel {
        display: none;
        width: 200px;
        min-height: 260px;
        border: 1px solid;
        @include theme-prop($prop--border-color, $key--border-donation);
        border-radius: 7px;

        position: absolute;
        top: 0;
        right: 0;
        z-index: 2000;

        @include theme-prop($prop--background-color, $key--background-donation);

        &.mobile-open {
            display: block;
        }
    }

    &__avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        top: 13px;
        left: 11px;
        overflow: hidden;

        background-color: #F4F0EC;

        svg {
            width: 13px;
            height: 16px;
            fill: url(#gradient-blue1) $green;
        }
    }

    &__close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;

        position: absolute;
        top: 6px;
        right: 6px;

        .icon-close {
            width: 16px;
            height: 16px;
            fill: $color-brown;
        }
    }

    &__main {
        padding: 66px $parts-padding 20px;
        border-bottom: 1px solid;
        @include theme-prop($prop--border-color, $key--border-donation);
        border-radius: 12px;

        @include theme-prop($prop--background-color, $key--background-body);
    }

    &__secondary {
        display: flex;
        flex-direction: column;
        min-height: 100px;
        padding-top: 23px;
        padding-bottom: 23px;
        padding-left: 17px;
        padding-right: $parts-padding;

        &--user {
            @include mq_for($bp_large) {
                display: none;
            }
        }
    }

    &__secondary-link {
        display: block;
        margin-bottom: 18px;

        @include theme-prop($prop--color, $key--text-semi);
        font: 500 13px/1 $ff-main;
    }

    &__journal-link {
        display: inline-block;
        margin-top: 9px;
        padding-bottom: 1px; // fixes tiny cutting of "у" character at the bottom (at least in chrome)

        font: 700 16px/1 $ff-main;
        letter-spacing: -0.01em;
        @include link-gradient();
    }

    &__join-link {
        margin-bottom: 15px;
    }

    &__logout-link {
        margin-top: 10px;
    }
}

.nav-link-group {
    &__item {
    }
}

.nav-button-1 {
    display: inline-block;
    min-width: 136px;
    height: 33px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-radius: 16px;
    padding-left: 17px;
    padding-right: 5px;
    padding-top: 7px;
    transition: .35s;

    @include theme-prop($prop--color, $key--text-body);
    font: 500 14px/19px $ff-main;
    letter-spacing: -0.04em;

    &--border-beige {
        border-color: transparentize($beige2, 0.5);

        &:hover {
            background: transparentize($beige2, 0.5);
        }
    }

    &--border-blue {
        border-color: $green2;
    }

    &--with-arrow {
        padding-right: 40px;
        position: relative;

        svg {
            width: 16px;
            height: 9px;
            position: absolute;
            right: 15px;
            top: 12px;
        }
    }

    &--arrow-beige {
        svg {
            fill: $beige2;
        }
    }

    &--arrow-blue {
        svg {
            fill: $green2;
        }
    }
}
