// the banner to Journal
.banner1 {
    @include light_dark(background-color, #DDFDFF, #214B64); // haven't found such colors in colors.scss

    &--journal {
        position: relative;
        z-index: 5; // higher than decor elements in "intro" block on main page

        @include mq_for($bp_480) {
            display: none;
        }
    }

    &__link {
        display: block;
        padding: 15px 0;

        color: inherit;
    }

    &__inner {
        display: flex;
    }

    &__text {
        // Figma styleName: T-18/bold;
        font-weight: 700;
        font-size: 18px;
        line-height: 1;
        letter-spacing: -0.01em;
        text-transform: lowercase;

        // text gradient
        background-color: $green;
        background-image: linear-gradient(270deg, $blue 0%, $green 96.12%);
        background-clip: text;
        background-size: 100%;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }

    &__arrow {
        margin-left: auto;

        svg {
            width: 18px;
            height: 11px;

            fill: url(#gradient-blue1) $color-link;
        }
    }
}

.hide-if-journal-banner-displayed {
    @include mq_upto($bp_480) {
        display: none !important;
    }
}


// The banner to the Friendly-guide for authors
.banner2 {
    $z-index-top: 5;
    $z-index-bottom: 1;

    display: flex;
    flex-direction: column;
    width: 285px;
    padding: 40px 30px 30px;
    box-sizing: border-box;
    border-radius: 22px;
    position: relative;
    overflow: hidden;

    @include theme-prop($prop--background-color, $key--background-donation);

    @include mq_for($bp_medium) {
        height: 350px;
    }

    &__title {
        margin-bottom: 20px;
        position: relative;
        z-index: $z-index-top;

        font: 600 21px/1 $ff-main;
    }

    &__text {
        position: relative;
        z-index: $z-index-top;

        font: 13px/16.9px $ff-main;
        letter-spacing: -0.01em;
    }

    &__button {
        position: relative;
        z-index: $z-index-top;

        @include mq_upto($bp_medium) {
            margin-top: 30px;
        }

        @include mq_for($bp_medium) {
            margin-top: auto;
        }
    }

    &__decor {
        width: 152px;
        height: 85px;
        margin-top: auto;

        position: absolute;
        right: -26px;
        bottom: 15px;
        z-index: $z-index-bottom;
        transform: scaleX(-1) rotate(30deg);
    }
}


// The slide of "Banners" slider on main page
.banner3 {
    $root: &;
    $img-left:  #{$root}--img-pos-left;
    $img-right: #{$root}--img-pos-right;

    &--bg-gradient-dark-light {
        background: linear-gradient(270deg, #2BD7E0 15.68%, #11A4D7 100%);
    }
    &--bg-gradient-light-dark {
        background: linear-gradient(90deg, #2BD7E0 0%, #11A4D7 100%);
    }
    &--bg-solid-light {
        background-color: #11A4D7;
    }
    &--bg-solid-dark {
        background-color: #18718F;
    }
    &--bg-solid-extra-dark {
        background-color: #001514;
    }
    &--bg-solid-purple {
        background-color: #212545;
    }
    &--bg-solid-lilac {
        background-color: #6660C8;
    }

    &__inner {
        // top-bottom paddings
        @include mq_upto($bp_medium) {
            padding-top: 30px;
            padding-bottom: 40px;
        }

        @include mq_for($bp_medium) {
            padding-top: 57px;
            padding-bottom: 83px;
        }

        // horizontal rules
        margin-left: auto;
        margin-right: auto;

        @include mq_upto($bp_480) {
            width: calc(100% - 40px);
        }

        @include mq_between($bp_480, $bp_large) {
            width: calc(100% - 60px);
        }

        @include mq_between($bp_large, $bp_1350) {
            max-width: 960px;
        }

        @include mq_between($bp_1350, $bp_1600) {
            max-width: 1120px;
        }

        @include mq_for($bp_1600) {
            max-width: 1200px;
        }

        // grid
        @include mq_upto($bp_medium) {
            display: grid;
            grid-auto-rows: 1fr;
            grid-template-columns: auto;
            grid-template-rows: auto auto auto auto;

            grid-template-areas:
                "title"
                "desc"
                "link"
                "thumb";
        }

        // modificators
        &--no-desc {
        }

        // adaptivity
        @include mq_for($bp_medium) {
            display: grid;
            grid-auto-rows: 1fr;
            grid-template-columns: 50% 50%;
            grid-template-rows: minmax(10px, min-content) minmax(10px, min-content) minmax(60px, min-content);
            gap: 20px 0;

            #{$img-left} & {
                grid-template-areas:
                "thumb title"
                "thumb desc"
                "thumb link";
            }

            #{$img-right} & {
                grid-template-areas:
                "title thumb"
                "desc  thumb"
                "link  thumb";
            }
        }
    }

    &__title {
        grid-area: title;
        padding-top: 4px;

        color: $color-white;

        @include mq_upto($bp_medium) {
            padding-bottom: 30px;
        }

        @include mq_upto($bp_large) {
            font: 600 24px/28.8px $ff-main;
        }

        @include mq_for($bp_large) {
            font: 600 33px/39.6px $ff-main;
        }
    }

    &__desc {
        grid-area: desc;

        position: relative;
        top: -10px;

        color: $color-white;

        @include mq_upto($bp_medium) {
            padding-bottom: 20px;
        }

        @include mq_upto($bp_large) {
            font: 500 15px/21px $ff-main;
        }

        @include mq_for($bp_large) {
            font: 500 16px/22.4px $ff-main;
        }
    }

    &__link-wrapper {
        grid-area: link;

        #{$root}__inner--no-desc & {
            grid-area: desc;

            @include mq_for($bp_medium) {
                margin-top: 25px;
            }
        }
    }

    &__link {
    }

    &__thumb-wrapper {
        grid-area: thumb;
        padding-left: 9px;

        @include mq_upto($bp_medium) {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        #{$img-left} & {
            @include mq_for($bp_medium) {
                padding-right: 30px;
            }
        }
    }

    &__thumb {
    }
}

// Email subscription banner on Main page
.banner4 {
    max-width: 794px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 25px 24px 30px;

    border-radius: 24px;
    letter-spacing: normal;
    @include theme-prop($prop--background-color, $key--background-donation);

    @include mq_for($bp_medium) {
        display: flex;
        align-items: center;
    }

    &__text {
        @include theme-prop($prop--color, $key--text-semi); // initial: #252525
        font: 600 20px/26px $ff-main;

        @include mq_upto($bp_medium) {
            margin-bottom: 20px;
        }

        @include mq_for($bp_medium) {
            padding-right: 20px;
        }
    }

    &__button {
    }
}
