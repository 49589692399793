/* General page */
.general-page {
    
    &__title {
        @include h1-title();
    }

    &__header {
        margin-top: 27px;
        margin-bottom: $base*4;
    }
}

.page-text + .page-form {
    margin-top: $base*6;
}


.page-form {
    
    &__errors {
        margin-bottom: $base*4;

        .alert {
            margin: 0;
        }
    }

    &__submit {
        margin-top: $base*6;
    }

    &__disclaymer {
        margin-top: $base*2;
    }

}


.page-toggle {
    &__title {
        @include h3-title();
        @include title-with-next($height: 18px, $icon: 11px);
        padding: $base*4 0;
        cursor: pointer;
    }

    & + & {
        border-top: 1px dashed $color-primary;
    }

    &__text {
        margin-bottom: 20px;
    }
}



.back-link {
    @include utility-link();
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    line-height: 15px;

    span {
        display: inline-block;
        line-height: 15px;
        margin-left: 9px;
    }

    .svg-icon {
        width: 12px;
        height: 12px;
        fill: currentColor;
        transform: rotate(180deg);
    }
}
