//@use '../elements/headers';

@import './django_forms_plus_crop';


.dfp-fieldset {
    $root: &;
    margin-bottom: 42px;

    &--subtitled {
        #{$root}__title {
            @extend .header-style2 !optional; // for some reason SCSS compiler doesn't see .header-style2, but with !optional it does о_О
        }
    }
}

.dfp-fieldslot {
    margin: 0 0 $base*4;
    position: relative;

    /* don't move this block below modifiers. It breaks the specificity. */
    input {
        width: 100%;
        padding: 8px 12px;
        @include form-field-border();
        @include form-field-input-text();
        @include theme-background-color('background-field-base');
        outline: none;

        &:focus:not([readonly]) {
            @include theme-background-color('background-field-focus');
            &::placeholder {
                opacity: 0.3;
            }
        }

        &[disabled] {
            color: $color-text-light;
        }

        &[readonly] {
            @include theme-text-color('text-placeholder');
        }
    }

    /* don't move this block below modifiers. It breaks the specificity. */
    textarea {
        resize: vertical;
        width: 100%;
        padding: 8px 12px;
        @include form-field-border();
        @include form-field-input-text();
        @include theme-background-color('background-field-base');


        &.size-small {
            height: 70px;
        }

        &.size-medium {
            height: 120px;
        }

        &.size-large {
            @include upto(large-screens) {
                height: 420px;
            }

            @include for(large-screens) {
                height: 300px;
            }
        }

        &:focus {
            @include theme-background-color('background-field-focus');
            &::placeholder {
                opacity: 0.3;
            }
        }
    }

    &--valid {
        .dfp-fieldslot__title {
            @include theme-text-color('success');
        }

        input {
            @include theme-border-color('success');
        }
    }

    &--invalid {
        .dfp-fieldslot__title {
            @include theme-text-color('error');
        }

        input {
            @include theme-border-color('error');
        }
    }

    &--softly-invalid {
        .dfp-fieldslot__title {
            @include theme-text-color('error');
        }

        input, textarea {
            @include theme-border-color('error');
        }
    }

    &__title {
        display: inline-block;
        @include form-title();
        margin-bottom: $base;
    }

    &__help-text {
        font-size: 80%;
        margin-bottom: $base*3;
    }

    &__soft-errors {
        @include form-error-text();
        padding: 3px 0 0 3px;
    }
}

.dfp-input-file {
    &__preview-placeholder {
    }

    &__preview {
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
    }

    &__preview-picture {
        display: block;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    &__input-wrapper {}

    &__input {
        font-size: 12px !important; // dirty
        border-width: 0 !important; // dirty
        background-color: transparent !important; // dirty
        padding: 0 !important; // dirty
        @include theme-text-color(text-body);
    }
}

// Radio buttons
.dfp-radio-list {
    &__item {}
}

html body .dfp-radio-item {  // dirty selector
    display: flex;

    &__input-wrapper {
        &:hover {
            cursor: pointer;
        }
    }

    &__input {
        width: 22px;
        height: 22px;
        border-width: 1px;
        border-style: solid;
        border-color: #c5cacc;
        padding: 4px;
        box-sizing: border-box;

        appearance: none;
        border-radius: 50%;
        @include theme-background-color('background-slight');

        &:hover {
            cursor: pointer;
            border-color: $green !important;
        }

        &:checked {
            border-color: $green !important;

            background-clip: content-box;
            background-image: radial-gradient(
                circle,
                $green 0%,
                $green 50%,
                transparent 60%,
                transparent 100%
            );
            background-color: transparent;
        }

        &:focus {

        }
    }

    &__label {
        display: block;
        padding-left: 10px;

        &:hover {
            cursor: pointer;
        }
    }
}

.fr-radio-2col {
    .dfp-radio-list {
        @include mq_for($bp_small) {
            display: flex;
        }

        &__item {
            @include mq_upto($bp_small) {
                margin-bottom: 5px;
            }

            @include mq_for($bp_small) {
                margin-left: 30px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.dpf-submit-indicator {
    margin: 20px 0;
    &:after {
        content: 'Отправляем запрос...'
    }
}

.dfp-submit-button--loading {
    opacity: 0.3;
}

// Redefines input:file default styles for the form on "Edit project -> Cover" page
.fr-upload-project-cover {
    .dfp-input-file__preview {
        width: 100%;
        height: auto;
        padding-bottom: 60%;
    }
    .dfp-input-file__preview-picture {
        position: absolute;
    }
}

.dfp-field-error {
    @include form-error-text();
    padding: 3px 0 0 3px;
}

.dfp-common-errors {
    @include alert-base();
    margin-bottom: 24px;

    color: $red;
        background: transparentize($red, 0.9);

    &__item {
    }
}

.dfp-message {
    margin-top: 20px;
    margin-bottom: 20px;

    &__close {
        display: none;
    }
}

/* Debug panel */
.dfp-debug-panel {
    &__header, &__header button {
        font-family: monospace;
    }

    &__content {
        font-size: 14px;
    }
}

/* SlugInput specific styles */
.dfp-input-slug {
    flex-wrap: wrap;

    @include for(large-screens) {
        display: flex;
        justify-content: space-between;
    }

    &__prefix {
        flex: 0 0 auto;
        margin-right: $base*2;
        @include form-prefix-text();
    }

    &__input-wrapper {
        width: auto;
        flex: 1 1 auto;
    }

    &__suggestions {
        flex-basis: 100%;

        font-size: 0.9em;
        line-height: 1.4;
    }
}

.dfp-suggestions {
    display: flex;
    margin-top: 10px;
    justify-content: right;

    &__prefix {
        margin-right: 5px;

        &:after {
            content: ':';
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        &:after {
            display: inline-block;
            content: ', ';
            margin-right: 5px;
        }

        &:last-child:after {
            display: none;
            content: '';
            margin-right: 0;
        }
    }

    &__item-link {
        @include utility-link();
    }
}


/* The content of "Crop image" modal window */
.dfp-crop {
    display: flex;
    flex-direction: column;

    &__wrapper {
        margin-bottom: 10px;

        img {
            display: block;
            max-width: 80vw;
            max-height: 80vh;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
    }

    &__button {
        margin-right: 20px;

        display: block;
        width: 140px;
        height: 27px;

        color: white;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 27px;

        border-radius: 15px;
        cursor: pointer;

        @include brand-gradient();

        &:last-child {
            margin-right: 0;
        }
    }
}

/* "Crop image" modal window (Material UI component) styles  */
.MuiModal-root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    background-color: #000000cc;
}

.disclaimer2 { // for "Profile - Payment" form
    margin-top: -20px;
    margin-bottom: 35px;
}

/* 2 columns fieldset layout for django_forms_plus */
.form-cols-2 {
    @include for(small-screens) {
        .dfp-fieldset__items {
            display: flex;
            flex-wrap: wrap;
        }
        .dfp-fieldslot {
            width: 48%;
            margin-right: 2%;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

/* 3 columns fieldset layout for amount fields */
.form-cols-3 {
    @include for(small-screens) {
        .dfp-fieldset__items {
            display: flex;
        }
        .dfp-fieldslot {
            margin-right: 5%;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
