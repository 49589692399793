/* Base styles */
body {
    @include body-text();
    @include theme-background-color('background-tint');

    &.scroll-lock {
        overflow: hidden;

        height: 100vh;
        width: 100vw;
        touch-action: none;
        //-webkit-overflow-scrolling: none;
        overflow: hidden;
        overscroll-behavior: none;
    }
}

[x-cloak], .hidden {
    display: none !important;
}

.the-site {
    max-width: $screen_limit;
    margin: 0 auto;
    min-height: 100vh;
    @include theme-background-color('background-body');
    @include theme-text-color('text-body');

    overflow: hidden; // for the decor background elements in "intro" block on main page (

    @include for(xxlarge-screens) {
        box-shadow: 1px 0px 5px 5px rgba(200,200,200, 0.13);
    }
}

.site-content {
    min-height: 70vh; 
}

.pic-place {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 5px;
}

.aspect {
    width: 100%;
    height: 0;
    position: relative;
}

.sep {
    display: inline-block;
    margin: 0 4px;
}

.opacity20 {
    opacity: 0.2;
}

